import request from '../request'


// 根据币种分页查询广告
export function walletList(data) {
	return request({
		url: '/coinswap/wallet/list',
		method: 'POST',
		data: data
	})
}

// 资金划转
export function coinswapTrans(data) {
	return request({
		url: '/coinswap/wallet/trans',
		method: 'POST',
		data: data
	})
}


// 获取币种缩略行情
export function symbolThumb(data) {
	return request({
		url: '/coinswap/symbol-thumb',
		method: 'POST',
		data: data
	})
}


// 查询最近成交记录
export function symbolInfo(data) {
	return request({
		url: '/coinswap/symbol-info',
		method: 'POST',
		data: data
	})
}


// 获取当前委托列表
export function orderCurrent(data) {
	return request({
		url: '/coinswap/order/current',
		method: 'POST',
		data: data
	})
}



// 获取历史委托列表
export function orderHistory(data) {
	return request({
		url: '/coinswap/order/history',
		method: 'POST',
		data: data
	})
}


//  查询最近成交记录
export function latestTrade(data) {
	return request({
		url: '/coinswap/latest-trade',
		method: 'POST',
		data: data
	})
}



//  获取某交易对详情mini
export function plateMini(data) {
	return request({
		url: '/coinswap/exchange-plate-mini',
		method: 'POST',
		data: data
	})
}

//  获取某交易对详情full
export function plateFull(data) {
	return request({
		url: '/coinswap/exchange-plate-full',
		method: 'POST',
		data: data
	})
}


//  获取用户指定合约币种的钱包信息
export function walletDetail(data) {
	return request({
		url: '/coinswap/wallet/detail',
		method: 'POST',
		data: data
	})
}


//  合约撤销单
export function orderCancel(data) {
	return request({
		url: '/coinswap/order/cancel',
		method: 'POST',
		data: data
	})
}


//  合约下单（开仓）- 金本位 二种操作类型：买入开多，卖出开多
export function orderOpen(data) {
	return request({
		url: '/coinswap/order/open',
		method: 'POST',
		data: data
	})
}

//  合约下单（开仓）- 金本位 二种操作类型：买入开多，卖出开多
export function orderClose(data) {
	return request({
		url: '/coinswap/order/close',
		method: 'POST',
		data: data
	})
}



//  更改持仓模式
export function switchPattern(data) {
	return request({
		url: '/coinswap/order/switch-pattern',
		method: 'POST',
		data: data
	})
}

//  改指定交易对的合约倍数
export function modifyLeverage(data) {
	return request({
		url: '/coinswap/order/modify-leverage',
		method: 'POST',
		data: data
	})
}
