<template>
	<div class="page-web page-ms page-bg">
		<Head />
		<div class="ustandard-section">
			<div class="ustandard-body">
				<!-- 移动端 -->
				<div class="mb-ui">
					<div class="ustandard-head">
						<div class="ustandard-head__hd">
							<el-select v-model="symbol" @change="pairChange">
								<el-option v-for="(item,index) in options" :key="index"
									:label="item.symbol+$t('udTxt4_1')" :value="item.symbol">
								</el-option>
							</el-select>
						</div>
						<div class="u-head-amount" :class="{buy:currentCoin.change>0,sell:currentCoin.change<0}">
							{{currentCoin.close | toFixed(4)}}
						</div>
						<div class="ustandard-head__bd">
							<div class="u-item">
								<div class="u-item__label">{{$t('mth2')}}</div>
								<div class="u-item__value"
									:class="{buy:currentCoin.change>0,sell:currentCoin.change<0}">
									{{currentCoin.rose}}
								</div>
							</div>
							<div class="u-item">
								<div class="u-item__label">{{$t('mTxt')}}</div>
								<div class="u-item__value">{{currentCoin.high | toFixed(4)}}</div>
							</div>
							<div class="u-item">
								<div class="u-item__label">{{$t('mTxt2')}}</div>
								<div class="u-item__value">{{currentCoin.low | toFixed(4)}}</div>
							</div>
							<div class="u-item">
								<div class="u-item__label">{{$t('mTxt3')}}</div>
								<div class="u-item__value">{{currentCoin.volume |  toFixed(4)}} {{currentCoin.coin}}
								</div>
							</div>
							<div class="u-item">
								<div class="u-item__label">{{$t('mTxt4')}}</div>
								<div class="u-item__value">{{currentCoin.feePercent |  toFixed(6)}} %</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 移动端 -->
				<div class="ustandard-tab">
					<div class="ustandard-tab-item" :class="{active:smCurrent == 1}" @click="smCurrent = 1">
						{{$t('chart')}}
					</div>
					<div class="ustandard-tab-item" :class="{active:smCurrent == 2}" @click="smCurrent = 2">
						{{$t('book')}}
					</div>
					<div class="ustandard-tab-item" :class="{active:smCurrent == 3}" @click="smCurrent = 3">
						{{$t('trade')}}
					</div>
				</div>
				<div class="ustandard-row ustandard-mb">
					<div class="ustandard-col1 flex_bd" :class="{show:smCurrent == 1}">
						<div class="u4-right handler">
							<el-button type="text" :class="current==1?'el-button--active':''" @click="currentClick(1)">
								{{$t('echat1')}}
							</el-button>
							<el-button type="text" :class="current==2?'el-button--active':''" @click="currentClick(2)">
								{{$t('echat2')}}
							</el-button>
						</div>
						<!-- pc端 -->
						<div class="ustandard-head">
							<div class="ustandard-head__hd">
								<el-select v-model="symbol" @change="pairChange">
									<el-option v-for="(item,index) in options" :key="index"
										:label="item.symbol+$t('udTxt4_1')" :value="item.symbol">
									</el-option>
								</el-select>
							</div>
							<div class="u-head-amount" :class="{buy:currentCoin.change>0,sell:currentCoin.change<0}">
								{{currentCoin.close | toFixed(4)}}
							</div>
							<div class="ustandard-head__bd">
								<div class="u-item">
									<div class="u-item__label">{{$t('mth2')}}</div>
									<div class="u-item__value"
										:class="{buy:currentCoin.change>0,sell:currentCoin.change<0}">
										{{currentCoin.rose}}
									</div>
								</div>
								<div class="u-item">
									<div class="u-item__label">{{$t('mTxt')}}</div>
									<div class="u-item__value">{{currentCoin.high | toFixed(4)}}</div>
								</div>
								<div class="u-item">
									<div class="u-item__label">{{$t('mTxt2')}}</div>
									<div class="u-item__value">{{currentCoin.low | toFixed(4)}}</div>
								</div>
								<div class="u-item">
									<div class="u-item__label">{{$t('mTxt3')}}</div>
									<div class="u-item__value">{{currentCoin.volume |  toFixed(4)}} {{currentCoin.coin}}
									</div>
								</div>
								<div class="u-item">
									<div class="u-item__label">{{$t('mTxt4')}}</div>
									<div class="u-item__value">{{currentCoin.feePercent |  toFixed(6)}} %</div>
								</div>
							</div>
						</div>
						<!-- pc端 -->
						<div class="u4-panel" v-show="current == 1">
							<!-- <div class="panel-head">
								<div class="u4-left flex">
									<div class="u4-select">
										<el-radio-group v-model="resolution">
											<el-radio-button label="Time"></el-radio-button>
											<el-radio-button label="1">M1</el-radio-button>
											<el-radio-button label="5">M5</el-radio-button>
											<el-radio-button label="15">M15</el-radio-button>
											<el-radio-button label="60">H1</el-radio-button>
											<el-radio-button label="240">H4</el-radio-button>
											<el-radio-button label="1W">W1</el-radio-button>
										</el-radio-group>
									</div>
									<div class="u4-select">
										<el-popover placement="bottom" width="200" trigger="click">
											<el-button class="range-btn" slot="reference">{{resolution2}} <i
													class="el-icon-caret-bottom el-icon--right"></i></el-button>
											<div class="bi-radio bi-radio-sm">
												<el-radio-group v-model="resolution2">
													<el-radio-button label="M1">M1</el-radio-button>
													<el-radio-button label="M5">M5</el-radio-button>
													<el-radio-button label="M15">M15</el-radio-button>
													<el-radio-button label="M30">M30</el-radio-button>
													<el-radio-button label="H1">H1</el-radio-button>
													<el-radio-button label="H4">H4</el-radio-button>
													<el-radio-button label="D1">D1</el-radio-button>
													<el-radio-button label="1W">{{$t('option2')}}</el-radio-button>
													<el-radio-button label="1M">{{$t('option3')}}</el-radio-button>
												</el-radio-group>
											</div>
										</el-popover>
									</div>
								</div>
								<div class="u4-right">
									<div class="u4-full">
										<el-button type="text"><i class="el-icon-full-screen"></i></el-button>
									</div>
								</div>
							</div> -->
							<div class="panel-body">
									<!-- 	// TODO:3 -->
								<div class="u4-box" style="padding-left:25px">
									<div class="u4-line" style="height:574px;" >
									<TV :symbol="symbol" ref="tv"></TV>
									</div>
								</div>
								<!-- <div class="u4-box">
									<div class="u4-head">
										<div class="u4-head-left">
											<div class="u4-item">
												<el-date-picker prefix-icon="el-icon-caret-bottom" format="yyyy/MM/dd"
													v-model="time" type="date" :clearable='false' :disabled="true">
												</el-date-picker>
											</div>
											<div class="u4-item">
												{{$t('mTxt5')}}：<span class="text-green">15265.2554</span>
											</div>
											<div class="u4-item">
												{{$t('mTxt6')}}：<span class="text-green">19754.1400</span>
											</div>
											<div class="u4-item">
												{{$t('mTxt7')}}：<span class="text-green">19743.1500</span>
											</div>
											<div class="u4-item">
												{{$t('mTxt8')}}：<span class="text-green">19749.8000</span>
											</div>
										</div>
									</div>
									<div class="u4-line">
										<img src="../../assets/photo/u2.png" alt="" style="width: 100%;height: 100%;">
									</div>
								</div> -->
							</div>
						</div>
						<div class="u4-panel" v-show="current == 2">
							<div class="depth-group flex">
								<div class="depth flex_bd" id="depth" v-if="current == 2"></div>
								<div class="depth flex_bd" id="depth2" v-if="current == 2"></div>
							</div>
						</div>
					</div>
					<div class="ustandard-col2" :class="{show:smCurrent == 2}">
						<div class="u1-panel">
							<el-tabs v-model="activeName5" @tab-click="handleClick">
								<el-tab-pane name="all">
									<span slot="label">
										<img src="../../assets/photo/web/block1.png" alt="">
									</span>
									<div class="ui-row clearfix">
										<div class="ui-table">
											<div class="ui-table-head">
												<div class="ui-table-tr">
													<div class="ui-table-th col1">
														{{$t('wth3')}}(USDT)
													</div>
													<div class="ui-table-th col2">
														{{$t('wtth4')}}({{ currentCoin.coin }})
													</div>
													<div class="ui-table-th col3">
														{{$t('total')}}({{ currentCoin.coin }})
													</div>
												</div>
											</div>
											<div class="ui-table-body">
												<div class="ui-table-up ui-table-height">
													<div class="ui-table-cell" v-for="(item,index) in plate.askRows"
														:key="index">
														<div class="ui-table-tr">
															<div class="ui-table-td">
																{{item.price | toFixed(baseCoinScale)}}
															</div>
															<div class="ui-table-td">
																{{item.amount | toFixed(coinScale)}}
															</div>
															<div class="ui-table-td">
																{{item.totalAmount | toFixed(coinScale)}}
															</div>
														</div>
														<div class="ui-table-progress"
															:style="{width: (item.totalAmount/plate.askTotle).toFixed(4) * 100 + '%'}">
														</div>
													</div>
												</div>
												<div class="ui-table-subtitle"
													:class="{ buy: currentCoin.change > 0, sell: currentCoin.change < 0 }">
													{{currentCoin.close}}
													<i class="el-icon-top" v-if="currentCoin.change > 0"></i>
													<i class="el-icon-bottom" v-else></i>
												</div>
											</div>
										</div>
										<div class="ui-table ui-table2">
											<div class="ui-table-head">
												<div class="ui-table-tr">
													<div class="ui-table-th col1">
														{{$t('wth3')}}(USDT)
													</div>
													<div class="ui-table-th col2">
														{{$t('wtth4')}}({{ currentCoin.coin }})
													</div>
													<div class="ui-table-th col3">
														{{$t('total')}}({{ currentCoin.coin }})
													</div>
												</div>
											</div>
											<div class="ui-table-body">
												<div class="ui-table-down ui-table-height">
													<div class="ui-table-cell" v-for="(item,index) in plate.bidRows"
														:key="index">
														<div class="ui-table-tr">
															<div class="ui-table-td">
																{{item.price | toFixed(baseCoinScale)}}
															</div>
															<div class="ui-table-td">
																{{item.amount | toFixed(coinScale)}}
															</div>
															<div class="ui-table-td">
																{{item.totalAmount | toFixed(coinScale)}}
															</div>
														</div>
														<div class="ui-table-progress"
															:style="{width: (item.totalAmount/plate.bidTotle).toFixed(4) * 100 + '%'}">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane name="buy">
									<span slot="label">
										<img src="../../assets/photo/web/block2.png" alt="">
									</span>
									<div class="ui-table">
										<div class="ui-table-head">
											<div class="ui-table-tr">
												<div class="ui-table-th col1">
													{{$t('wth3')}}(USDT)
												</div>
												<div class="ui-table-th col2">
													{{$t('wtth4')}}({{ currentCoin.coin }})
												</div>
												<div class="ui-table-th col3">
													{{$t('total')}}({{ currentCoin.coin }})
												</div>
											</div>
										</div>
										<div class="ui-table-body">
											<div class="ui-table-subtitle"
												:class="{ buy: currentCoin.change > 0, sell: currentCoin.change < 0 }">
												{{currentCoin.close}}
												<i class="el-icon-top" v-if="currentCoin.change > 0"></i>
												<i class="el-icon-bottom" v-else></i>
											</div>
											<div class="ui-table-down">
												<div class="ui-table-cell" v-for="(item,index) in plate.bidRows"
													:key="index">
													<div class="ui-table-tr">
														<div class="ui-table-td">
															{{item.price | toFixed(baseCoinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.amount | toFixed(coinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.totalAmount | toFixed(coinScale)}}
														</div>
													</div>
													<div class="ui-table-progress"
														:style="{width: (item.totalAmount/plate.bidTotle).toFixed(4) * 100 + '%'}">
													</div>
												</div>
											</div>
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane name="sell">
									<span slot="label">
										<img src="../../assets/photo/web/block3.png" alt="">
									</span>
									<div class="ui-table">
										<div class="ui-table-head">
											<div class="ui-table-tr">
												<div class="ui-table-th col1">
													{{$t('wth3')}}(USDT)
												</div>
												<div class="ui-table-th col2">
													{{$t('wtth4')}}({{ currentCoin.coin }})
												</div>
												<div class="ui-table-th col3">
													{{$t('total')}}({{ currentCoin.coin }})
												</div>
											</div>
										</div>
										<div class="ui-table-body">
											<div class="ui-table-up">
												<div class="ui-table-cell" v-for="(item,index) in plate.askRows"
													:key="index">
													<div class="ui-table-tr">
														<div class="ui-table-td">
															{{item.price | toFixed(baseCoinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.amount | toFixed(coinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.totalAmount | toFixed(coinScale)}}
														</div>
													</div>
													<div class="ui-table-progress"
														:style="{width: (item.totalAmount/plate.askTotle).toFixed(4) * 100 + '%'}">
													</div>
												</div>
											</div>
											<div class="ui-table-subtitle"
												:class="{ buy: currentCoin.change > 0, sell: currentCoin.change < 0 }">
												{{currentCoin.close}}
												<i class="el-icon-top" v-if="currentCoin.change > 0"></i>
												<i class="el-icon-bottom" v-else></i>
											</div>
										</div>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
					</div>
					<div class="ustandard-col3" :class="{show:smCurrent == 3}">
						<div class="ustandard-table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="price" :label="$t('wth3')+'(USDT)'">
									<template slot-scope="scope">
										<span v-if="scope.row.direction == 'BUY'"
											class="text-green">{{ scope.row.price | toFixed(baseCoinScale)}}</span>
										<span v-if="scope.row.direction == 'SELL'"
											class="text-red">{{ scope.row.price | toFixed(baseCoinScale)}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="amount" :label="$t('wtth4')+'('+currentCoin.coin+')'" align="right">
                  <template slot-scope="scope">
                    <span>{{ scope.row.amount | toFixed(coinScale)}}</span>
                  </template>
                </el-table-column>
								<el-table-column prop="time" :label="$t('wth1')" align="right">
									<template slot-scope="scope">
										<span>{{ scope.row.time | datefmt('HH:mm:ss')}}</span>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
				<div class="ustandard-row">
					<div class="ustandard-col1 flex_bd">
						<div class="u3-panel" style="overflow: hidden;width: 100%;">
							<div class="u2-tab">
								<el-tabs v-model="activeName3" @tab-click="handleOrderClick">
									<el-tab-pane :label="$t('mTab2')" name="firsth">
										<div class="u3-table">
											<el-table :data="positionList" style="width: 100%" height="300">
												<el-table-column :label="$t('uth1')">
													<template slot-scope="scope">
														<div class="u3-btns">
															<el-button type="success"
																v-if="scope.row.direction == 'Buy'">
																{{$t('uTxt15')}} {{ scope.row.leverage }}X
															</el-button>
															<el-button type="danger" v-else>
																{{$t('uTxt16')}} {{ scope.row.leverage }}X
															</el-button>
														</div>
													</template>
												</el-table-column>
												<el-table-column prop="pl" :label="$t('uth2')">
													<template slot-scope="scope">
														<span class="text-green"
															v-if="scope.row.pl>0">+{{ scope.row.pl.toFixed(4) }}
															USDT</span>
														<span class="text-red" v-else>{{ scope.row.pl.toFixed(4) }}
															USDT</span>
													</template>
												</el-table-column>
												<el-table-column prop="plRatio" :label="$t('uth3')">
													<template slot-scope="scope">
														<span class="text-green"
															v-if="scope.row.plRatio > 0">{{ (scope.row.plRatio*100).toFixed(2) }}%</span>
														<span class="text-red"
															v-else>{{ (scope.row.plRatio*100).toFixed(2) }}%</span>
													</template>
												</el-table-column>
												<el-table-column prop="position" :label="$t('uth4')">
													<template slot-scope="scope">
														<span>{{ scope.row.position }}{{$t('zhang')}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="avaPosition" :label="$t('uth5')">
													<template slot-scope="scope">
														<span>{{ scope.row.avaPosition }}{{$t('zhang')}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="openPrice" :label="$t('uth6')">
													<template slot-scope="scope">
														<span>{{ scope.row.openPrice.toFixed(4) }} USDT</span>
													</template>
												</el-table-column>
												<el-table-column prop="forcePrice" :label="$t('uth7')">
													<template slot-scope="scope">
														<span>{{ scope.row.forcePrice.toFixed(4) }} USDT</span>
													</template>
												</el-table-column>
												<el-table-column prop="margin" :label="$t('uth8')">
													<template slot-scope="scope">
														<span>{{ scope.row.margin.toFixed(4) }} USDT</span>
													</template>
												</el-table-column>
												<el-table-column prop="mRatio" :label="$t('uth9')">
													<template slot-scope="scope">
														<span>{{ (scope.row.mRatio*100).toFixed(2) }}%</span>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-tab-pane>
									<el-tab-pane :label="$t('wTxt2')" name="second">
										<div class="u3-table">
											<el-table :data="tableData3" style="width: 100%" height="300">
												<el-table-column prop="createTime" :label="$t('wth1')">
													<template slot-scope="scope">
														<span>{{ scope.row.createTime | datefmt('YYYY-MM-DD HH:mm:ss')}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="entrustType" :label="$t('wth12')">
													<template slot-scope="scope">
														<span
															v-if="scope.row.entrustType == 'OPEN'">{{$t('uTab')}}</span>
														<span v-else>{{$t('uTab2')}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="direction" :label="$t('wth2')">
													<template slot-scope="scope">
														<span v-if="scope.row.entrustType == 'OPEN'">
															<span v-if="scope.row.direction == 'BUY'"
																class="text-green">
																{{$t('uTxt12')}}
															</span>
															<span v-else class="text-red">{{$t('uTxt13')}}</span>
														</span>
														<span v-else>
															<span v-if="scope.row.direction == 'BUY'"
																class="text-green">
																{{$t('closeup')}}
															</span>
															<span v-else class="text-red">{{$t('closedown')}}</span>
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="type" :label="$t('wth6')">
													<template slot-scope="scope">
														<span v-if="scope.row.type == 'LIMIT_PRICE'">
															{{$t('limited_price')}}
														</span>
														<span v-else-if="scope.row.type == 'MARKET_PRICE'">
															{{$t('market_price')}}
														</span>
														<span v-else>
															{{$t('spot_price')}}
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="triggerPrice" :label="$t('wth7')">
													<template slot-scope="scope">
														<span>
															{{scope.row.triggerPrice.toFixed(2)}}
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="entrustPrice" :label="$t('uTxt11')">
													<template slot-scope="scope">
														<span>
															{{scope.row.entrustPrice.toFixed(2)}}
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="tradedPrice" :label="$t('wth8')">
													<template slot-scope="scope">
														<span>
															{{scope.row.tradedPrice.toFixed(2)}}
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="principalAmount" :label="$t('wth13')">
													<template slot-scope="scope">
														<span>
															{{scope.row.principalAmount.toFixed(2)}} USDT
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="volume" :label="$t('wth9')">
													<template slot-scope="scope">
														<span>
															{{scope.row.volume}} {{$t('zhang')}}
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="status" :label="$t('wth11')">
													<template slot-scope="scope">
														<span v-if="scope.row.status == 'ENTRUST_ING' ">
															{{$t('ent_status1')}}
														</span>
														<span v-else-if="scope.row.status == 'ENTRUST_CANCEL' ">
															{{$t('ent_status2')}}
														</span>
														<span v-else-if="scope.row.status == 'ENTRUST_FAILURE' ">
															{{$t('ent_status3')}}
														</span>
														<span v-else-if="scope.row.status == 'ENTRUST_SUCCESS' ">
															{{$t('ent_status4')}}
														</span>
														<span v-else> - </span>
													</template>
												</el-table-column>
												<el-table-column :label="$t('th5')">
													<template slot-scope="scope">
														<div class="turnover-btns">
															<el-button type="text" @click="handleCancel(scope.row.id)">
																{{$t('wbtn')}}
															</el-button>
														</div>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-tab-pane>
									<el-tab-pane :label="$t('hisTxt')" name="third">
										<div class="u3-table">
											<el-table :data="tableData4" style="width: 100%" height="300">
												<el-table-column prop="createTime" :label="$t('wth1')" width="150">
													<template slot-scope="scope">
														<span>{{ scope.row.createTime | datefmt('YYYY-MM-DD HH:mm:ss')}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="entrustType" :label="$t('wth12')">
													<template slot-scope="scope">
														<span
															v-if="scope.row.entrustType == 'OPEN'">{{$t('uTab')}}</span>
														<span v-else>{{$t('uTab2')}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="direction" :label="$t('wth2')">
													<template slot-scope="scope">
														<span v-if="scope.row.entrustType == 'OPEN'">
															<span v-if="scope.row.direction == 'BUY'"
																class="text-green">
																{{$t('uTxt12')}}
															</span>
															<span v-else class="text-red">{{$t('uTxt13')}}</span>
														</span>
														<span v-else>
															<span v-if="scope.row.direction == 'BUY'"
																class="text-green">
																{{$t('closeup')}}
															</span>
															<span v-else class="text-red">{{$t('closedown')}}</span>
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="type" :label="$t('wth6')">
													<template slot-scope="scope">
														<span v-if="scope.row.type == 'LIMIT_PRICE'">
															{{$t('limited_price')}}
														</span>
														<span v-else-if="scope.row.type == 'MARKET_PRICE'">
															{{$t('market_price')}}
														</span>
														<span v-else>
															{{$t('spot_price')}}
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="triggerPrice" :label="$t('wth7')">
													<template slot-scope="scope">
														<span>
															{{scope.row.triggerPrice.toFixed(2)}}
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="entrustPrice" :label="$t('uTxt11')">
													<template slot-scope="scope">
														<span>
															{{scope.row.entrustPrice.toFixed(2)}}
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="tradedPrice" :label="$t('wth8')">
													<template slot-scope="scope">
														<span>
															{{scope.row.tradedPrice.toFixed(2)}}
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="principalAmount" :label="$t('wth13')">
													<template slot-scope="scope">
														<span>
															{{scope.row.principalAmount.toFixed(2)}} USDT
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="volume" :label="$t('wth9')">
													<template slot-scope="scope">
														<span>
															{{scope.row.volume}} {{$t('zhang')}}
														</span>
													</template>
												</el-table-column>
												<el-table-column :label="$t('czTxt8')">
													<template slot-scope="scope">
														<span v-if="scope.row.status != 'ENTRUST_CANCEL' ">
															<span v-if="scope.row.entrustType == 'OPEN'">
																{{scope.row.openFee}}
															</span>
															<span v-if="scope.row.entrustType == 'CLOSE'">
																{{scope.row.closeFee}}
															</span>
														</span>
														<span v-else>
															--
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="profitAndLoss" :label="$t('wth10')">
													<template slot-scope="scope">
														<span v-if="scope.row.entrustType == 'CLOSE' ">
															<span v-if="scope.row.profitAndLoss < 0" class="text-red">
																{{scope.row.profitAndLoss.toFixed(2)}}
															</span>
															<span v-else class="text-green">
																{{scope.row.profitAndLoss.toFixed(2)}}
															</span>
														</span>
														<span v-else>
															--
														</span>
													</template>
												</el-table-column>
												<el-table-column prop="status" :label="$t('wth11')">
													<template slot-scope="scope">
														<span v-if="scope.row.status == 'ENTRUST_ING' ">
															{{$t('ent_status1')}}
														</span>
														<span v-else-if="scope.row.status == 'ENTRUST_CANCEL' ">
															{{$t('ent_status2')}}
														</span>
														<span v-else-if="scope.row.status == 'ENTRUST_FAILURE' ">
															{{$t('ent_status3')}}
														</span>
														<span v-else-if="scope.row.status == 'ENTRUST_SUCCESS' ">
															{{$t('ent_status4')}}
														</span>
														<span v-else>{{$t('ent_statusblast')}}</span>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-tab-pane>
								</el-tabs>
							</div>
						</div>
					</div>
					<div class="ustandard-col2 ustandard-col-top" style="background-color: var(--range-block);">
						<div class="u2-panel">
							<el-tabs v-model="activeName" type="card">
								<el-tab-pane :label="$t('uTab')" name="first">
									<div class="u2-content u2-tab" style="padding-bottom: 5px;">
										<el-tabs v-model="activeName2">
											<el-tab-pane :label="$t('dhTxt2')" name="1">
												<div class="u2-body" style="padding-bottom: 0;">
													<el-form ref="form" :model="form" class="u2-form"
														style="margin-bottom: 0;">
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('wth3')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form.entrustPrice"
																		:controls="false">
																	</el-input-number> -->
																	<el-input type="number"
																		:placeholder="$t('trplaceholder3')"
																		v-model.number="form.entrustPrice"
																		@input="entrustPriceChange">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{currentCoin.base}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('wtth4')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form.volume"
																		:controls="false" :precision="0">
																	</el-input-number> -->
																	<el-input type="number"
																		v-model.number="form.volume" :min="0">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-step">
																<div class="u2-step-inner">
																	<el-slider v-model="form.sliderOpenPercent"
																		:marks="marks" :step="25" show-stops
																		:show-tooltip="false">
																	</el-slider>
																</div>
															</div>
															<div class="u2-sheet">
																<div class="u2-sheet-item"><span
																		class="text-green">{{$t('uTxt9')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{avaOpenBuy}}
																	</span>{{$t('zhang')}}
																</div>
																<div class="u2-sheet-item"><span
																		class="text-red">{{$t('uTxt10')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{avaOpenSell}}
																	</span>{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
													</el-form>
												</div>
											</el-tab-pane>
											<el-tab-pane :label="$t('trTxt2')" name="0">
												<div class="u2-body" style="padding-bottom: 0;">
													<el-form ref="form" :model="form2" class="u2-form"
														style="margin-bottom: 0;">
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('uTxt11')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<el-input :placeholder="$t('uplaceholder2')"
																		class="placeholder-right" readonly>
																	</el-input>
																</div>
																<div class="u2-form-item__ft">

																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('wtth4')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form2.volume"
																		:controls="false" :precision="0">
																	</el-input-number> -->
																	<el-input type="number"
																		v-model.number="form2.volume" :min="0">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-step">
																<div class="u2-step-inner">
																	<el-slider v-model="form2.sliderOpenPercent"
																		:marks="marks" :step="25" show-stops
																		:show-tooltip="false">
																	</el-slider>
																</div>
															</div>
															<div class="u2-sheet">
																<div class="u2-sheet-item"><span
																		class="text-green">{{$t('uTxt9')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{avaOpenBuy}}
																	</span>{{$t('zhang')}}
																</div>
																<div class="u2-sheet-item"><span
																		class="text-red">{{$t('uTxt10')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{avaOpenSell}}
																	</span>{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
													</el-form>
												</div>
											</el-tab-pane>
											<el-tab-pane :label="$t('uTab3')" name="2">
												<div class="u2-body" style="padding-bottom: 0;">
													<el-form ref="form" :model="form3" class="u2-form"
														style="margin-bottom: 0;">
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('wth7')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form3.triggerPrice"
																		:placeholder="$t('uplaceholder2')"
																		:controls="false">
																	</el-input-number> -->
																	<el-input type="number"
																		:placeholder="$t('uplaceholder2')"
																		v-model.number="form3.triggerPrice"
																		@input="triggerPriceChange">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{currentCoin.base}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('uTxt11')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form3.entrustPrice"
																		:placeholder="$t('triggleplaceholder')"
																		:controls="false">
																	</el-input-number> -->
																	<el-input type="number"
																		:placeholder="$t('triggleplaceholder')"
																		v-model.number="form3.entrustPrice"
																		@input="entrustPriceChange3">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{currentCoin.base}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('wtth4')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form3.volume"
																		:controls="false" :precision="0">
																	</el-input-number> -->
																	<el-input type="number"
																		v-model.number="form3.volume" :min="0">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-sheet" style="margin-top: 0;">
																<div class="u2-sheet-item"><span
																		class="text-green">{{$t('uTxt9')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{avaOpenBuy}}
																	</span>{{$t('zhang')}}
																</div>
																<div class="u2-sheet-item"><span
																		class="text-red">{{$t('uTxt10')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{avaOpenSell}}
																	</span>{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
													</el-form>
												</div>
											</el-tab-pane>
										</el-tabs>
										<div class="u2-foot" v-if="isLogin">
											<el-button class="el-button--light" @click="onOpen(0)">{{$t('uTxt12')}}
											</el-button>
											<el-button type="danger" @click="onOpen(1)">{{$t('uTxt13')}}</el-button>
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane :label="$t('uTab2')" name="second">
									<div class="u2-content u2-tab" style="padding-bottom: 5px;">
										<el-tabs v-model="activeName2">
											<el-tab-pane :label="$t('dhTxt2')" name="1">
												<div class="u2-body" style="padding-bottom: 0;">
													<el-form ref="form" :model="form" class="u2-form"
														style="margin-bottom: 0;">
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('wth3')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form.entrustPrice"
																		:controls="false">
																	</el-input-number> -->
																	<el-input type="number"
																		:placeholder="$t('trplaceholder3')"
																		v-model.number="form.entrustPrice"
																		@input="entrustPriceChange">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{currentCoin.base}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('wtth4')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form.volume"
																		:controls="false" :precision="0">
																	</el-input-number> -->
																	<el-input type="number"
																		v-model.number="form.volume" :min="0">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-step">
																<div class="u2-step-inner">
																	<el-slider v-model="form.sliderClosePercent"
																		:marks="marks" :step="25" show-stops
																		:show-tooltip="false">
																	</el-slider>
																</div>
															</div>
															<div class="u2-sheet">
																<div class="u2-sheet-item"><span
																		class="text-green">{{$t('uTxt17')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{contractWalletInfo.coinBuyPosition}}
																	</span>{{$t('zhang')}}
																</div>
																<div class="u2-sheet-item"><span
																		class="text-red">{{$t('uTxt18')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{contractWalletInfo.coinSellPosition}}
																	</span>{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
													</el-form>
												</div>
											</el-tab-pane>
											<el-tab-pane :label="$t('trTxt2')" name="0">
												<div class="u2-body" style="padding-bottom: 0;">
													<el-form ref="form" :model="form2" class="u2-form"
														style="margin-bottom: 0;">
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('uTxt11')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<el-input :placeholder="$t('uplaceholder2')"
																		class="placeholder-right" readonly>
																	</el-input>
																</div>
																<div class="u2-form-item__ft">

																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('wtth4')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form2.volume"
																		:controls="false" :precision="0">
																	</el-input-number> -->
																	<el-input type="number"
																		v-model.number="form2.volume" :min="0">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-step">
																<div class="u2-step-inner">
																	<el-slider v-model="form2.sliderClosePercent"
																		:marks="marks" :step="25" show-stops
																		:show-tooltip="false">
																	</el-slider>
																</div>
															</div>
															<div class="u2-sheet">
																<div class="u2-sheet-item"><span
																		class="text-green">{{$t('uTxt17')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{contractWalletInfo.coinBuyPosition}}
																	</span>{{$t('zhang')}}
																</div>
																<div class="u2-sheet-item"><span
																		class="text-red">{{$t('uTxt18')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{contractWalletInfo.coinSellPosition}}
																	</span>{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
													</el-form>
												</div>
											</el-tab-pane>
											<el-tab-pane :label="$t('uTab3')" name="2">
												<div class="u2-body" style="padding-bottom: 0;">
													<el-form ref="form" :model="form3" class="u2-form"
														style="margin-bottom: 0;">
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('wth7')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form3.triggerPrice"
																		:placeholder="$t('uplaceholder2')"
																		:controls="false">
																	</el-input-number> -->
																	<el-input type="number"
																		:placeholder="$t('uplaceholder2')"
																		v-model.number="form3.triggerPrice"
																		@input="triggerPriceChange">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{currentCoin.base}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('uTxt11')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form3.entrustPrice"
																		:placeholder="$t('triggleplaceholder')"
																		:controls="false">
																	</el-input-number> -->
																	<el-input type="number"
																		:placeholder="$t('triggleplaceholder')"
																		v-model.number="form3.entrustPrice"
																		@input="entrustPriceChange3">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{currentCoin.base}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-form-item">
																<div class="u2-form-item__hd">
																	{{$t('wtth4')}}
																</div>
																<div class="u2-form-item__bd flex_bd">
																	<!-- <el-input-number v-model="form3.volume"
																		:controls="false" :precision="0">
																	</el-input-number> -->
																	<el-input type="number"
																		v-model.number="form3.volume" :min="0">
																	</el-input>
																</div>
																<div class="u2-form-item__ft">
																	{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
														<el-form-item>
															<div class="u2-sheet" style="margin-top: 0;">
																<div class="u2-sheet-item"><span
																		class="text-green">{{$t('uTxt17')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{contractWalletInfo.coinBuyPosition}}
																	</span>{{$t('zhang')}}
																</div>
																<div class="u2-sheet-item"><span
																		class="text-red">{{$t('uTxt18')}}：</span>≈<span
																		class="u2-sheet-value">
																		{{contractWalletInfo.coinSellPosition}}
																	</span>{{$t('zhang')}}
																</div>
															</div>
														</el-form-item>
													</el-form>
												</div>
											</el-tab-pane>
										</el-tabs>
										<div class="u2-foot" v-if="isLogin">
											<el-button class="el-button--light" @click="onClose(0)">{{$t('uTxt12')}}
											</el-button>
											<el-button type="danger" @click="onClose(1)">{{$t('uTxt13')}}</el-button>
										</div>
									</div>
								</el-tab-pane>
							</el-tabs>
							<div class="u2-panel__foot">
								<div class="u2-foot" v-if="!isLogin">
									<router-link to="/register" class="el-button el-button--warning">{{$t('ureg')}}
									</router-link>
									<router-link to="/login" class="el-button el-button--info">{{$t('sign')}}
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="ustandard-col3 ustandard-col-top" style="background-color: var(--range-block);">
						<div class="ustandard-account">
							<div class="account-head">
								<div class="my-account">{{$t('uTxt14')}}</div>
								<div class="account-zj" @click="zjTransfer">{{$t('transfer')}}</div>
							</div>
							<div class="account-body">
								<div class="account-cell">
									<div class="account-cell__hd">
										{{$t('ulabel1')}}
									</div>
									<div class="account-cell__bd flex_bd">
										<div class="account-mode">
											<el-button type="success" @click="showMarginModeModal">
												<span v-if="radio == '1'">{{$t('ubtn2')}}</span>
												<span v-else>{{$t('ubtn')}}</span>
											</el-button>
										</div>
									</div>
								</div>
								<div class="account-cell">
									<div class="account-cell__hd">
										{{$t('uth1')}}
									</div>
									<div class="account-cell__bd flex_bd">
										<div class="account-btns">
											<el-row :gutter="10">
												<el-col :span="12">
													<el-button type="success" @click="showAdjustLeverage(1)">
														{{buyLeverage}}X{{$t('ulabel5')}}
													</el-button>
												</el-col>
												<el-col :span="12">
													<el-button type="danger" @click="showAdjustLeverage(2)">
														{{sellLeverage}}X{{$t('ulabel6')}}
													</el-button>
												</el-col>
											</el-row>
										</div>
									</div>
								</div>
								<div class="account-cell">
									<div class="account-cell__hd">
										{{$t('ulabel2')}}
									</div>
									<div class="account-cell__bd flex_bd">
										{{totalQuanyi.toFixed(4)}}
									</div>
								</div>
								<div class="account-cell">
									<div class="account-cell__hd">
										{{$t('bith3')}}
									</div>
									<div class="account-cell__bd flex_bd">
										{{profitAndLoss.toFixed(4)}}
									</div>
								</div>
								<div class="account-cell">
									<div class="account-cell__hd">
										{{$t('bith4')}}
									</div>
									<div class="account-cell__bd flex_bd">
										{{contractWalletInfo.coinBalance.toFixed(4)}}
									</div>
								</div>
								<div class="account-cell">
									<div class="account-cell__hd">
										{{$t('ulabel3')}}
									</div>
									<div class="account-cell__bd flex_bd">
										{{(contractWalletInfo.coinBuyPrincipalAmount + contractWalletInfo.coinSellPrincipalAmount).toFixed(4)}}
									</div>
								</div>
								<div class="account-cell">
									<div class="account-cell__hd">
										{{$t('bith6')}}
									</div>
									<div class="account-cell__bd flex_bd">
										{{contractWalletInfo.coinFrozenBalance.toFixed(4)}}
									</div>
								</div>
								<div class="account-cell">
									<div class="account-cell__hd">
										{{$t('ulabel4')}}
									</div>
									<div class="account-cell__bd flex_bd">
										{{assetRate | percent}}%
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible" width="490px" custom-class="custom-dialog">
			<div slot="title" class="dialog-title"><b>{{$t('transfer')}}-USDT</b></div>
			<div class="dialog-content">
				<div class="transfer-form">
					<div class="transfer-form-item">
						<img src="../../assets/photo/web/form.png" alt="">
						<div class="transfer-form-item__label">{{$t('czth2')}}</div>
						<div class="flex_bd">
							<el-select v-model="form" :placeholder="$t('meun10_1')+'(USDT)'" style="width: 100%;">
								<el-option :label="$t('option1')" value="day"></el-option>
								<el-option :label="$t('option2')" value="week"></el-option>
								<el-option :label="$t('option3')" value="month"></el-option>
							</el-select>
						</div>
					</div>
					<div class="transfer-form-icon">
						<div class="left-icon"><i class="el-icon-bottom"></i></div>
						<div class="right-icon"><i class="el-icon-sort"></i></div>
					</div>
					<div class="transfer-form-item">
						<img src="../../assets/photo/web/to.png" alt="">
						<div class="transfer-form-item__label">{{$t('czth3')}}</div>
						<div class="flex_bd">
							<el-select v-model="to" :placeholder="$t('uplaceholder')" style="width: 100%;">
								<el-option :label="'BTC/USDT '+$t('udTxt4_1')" value="BTC"></el-option>
								<el-option :label="'ETH/USDT '+$t('udTxt4_1')" value="ETH"></el-option>
								<el-option :label="'LTC/USDT '+$t('udTxt4_1')" value="LTC"></el-option>
								<el-option :label="'DOGE/USDT '+$t('udTxt4_1')" value="DOGE"></el-option>
								<el-option :label="'EOS/USDT '+$t('udTxt4_1')" value="EOS"></el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div class="quota-cell">
					<div class="quota-cell-item">{{$t('udTxt5')}}：7997998864947944.00</div>
					<div class="quota-cell-item">{{$t('udTxt5')}}：0.00</div>
				</div>
				<div class="transfer-cell">
					<div class="transfer-cell__hd">{{$t('udTxt6')}}</div>
					<div class="transfer-cell__bd">
						<div class="transfer-input flex_bd">
							<el-input></el-input>
						</div>
						<el-button type="text">{{$t('udTxt7')}}</el-button>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-row :gutter="14">
					<el-col :span="12">
						<el-button @click="dialogVisible = false">{{$t('reset')}}</el-button>
					</el-col>
					<el-col :span="12">
						<el-button type="primary" @click="dialogVisible = false">{{$t('save')}}</el-button>
					</el-col>
				</el-row>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible2" width="384px" custom-class="custom-dialog">
			<div slot="title" class="dialog-title"><b>{{$t('udTxt2')}}</b></div>
			<div class="dialog-content">
				<div class="adjust-cell">
					<div class="adjust-cell__hd">
						{{$t('udTxt3')}}
					</div>
					<div class="adjust-cell__bd">
						<div class="u2-number" v-if="leverageType==2 && changeLeverageType==1">
							<el-input-number v-model="buyLeverage" :min="minLeverage" :max="maxLeverage">
							</el-input-number>
						</div>
						<div class="adjust-radio" v-if="leverageType==1 && changeLeverageType==1">
							<el-radio-group v-model="buyLeverage">
								<el-radio-button :label="item" v-for="item in leverageList" :key="item">
									{{item}}X
								</el-radio-button>
							</el-radio-group>
						</div>
						<div class="u2-number" v-if="leverageType==2 && changeLeverageType==2">
							<el-input-number v-model="sellLeverage" :min="minLeverage" :max="maxLeverage">
							</el-input-number>
						</div>
						<div class="adjust-radio" v-if="leverageType==1 && changeLeverageType==2">
							<el-radio-group v-model="sellLeverage">
								<el-radio-button :label="item" v-for="item in leverageList" :key="item">
									{{item}}X
								</el-radio-button>
							</el-radio-group>
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="adjustLeverage">{{$t('confirm')}}</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible3" width="384px" custom-class="custom-dialog">
			<div slot="title" class="dialog-title"><b>{{$t('udTxt')}}</b></div>
			<div class="dialog-content">
				<div class="bz-row">
					<el-radio-group v-model="radio" @input="radioInput">
						<el-radio-button label="1">
							{{$t('ubtn2')}}
						</el-radio-button>
						<el-radio-button label="0">
							{{$t('ubtn')}}
						</el-radio-button>
					</el-radio-group>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible3 = false">{{$t('confirm')}}</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible4" width="380px" custom-class="custom-dialog custom-dialog-tip">
			<div slot="title" class="dialog-title"><b>{{$t('wtip')}}</b></div>
			<div class="dialog-content">
				<div class="dialog-text">
					{{$t('wtiptxt')}}？
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-row :gutter="14">
					<el-col :span="12">
						<el-button @click="dialogVisible4 = false">{{$t('cancel')}}
						</el-button>
					</el-col>
					<el-col :span="12">
						<el-button type="primary" @click="orderConfirm">{{$t('confirm2')}}
						</el-button>
					</el-col>
				</el-row>
			</div>
		</el-dialog>
		<!-- 移动端 -->
		<div class="mb-foot-height">
			<div class="mb-foot">
				<el-row :gutter="15">
					<el-col :span="12">
						<div class="u2-foot">
							<el-button class="el-button--light" @click="drawer = true">
								{{$t('uTxt12')}}
							</el-button>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="u2-foot">
							<el-button type="danger" @click="drawer = true">
								{{$t('uTxt13')}}
							</el-button>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- 操作 -->
		<el-drawer :visible.sync="drawer" direction="btt" size="70%">
			<div class="ustandard-account" style="background-color: unset;">
				<div class="account-body">
					<div class="account-cell">
						<div class="account-cell__hd">
							{{$t('ulabel1')}}
						</div>
						<div class="account-cell__bd flex_bd">
							<div class="account-mode">
								<el-button type="success" @click="showMarginModeModal">
									<span v-if="radio == '1'">{{$t('ubtn2')}}</span>
									<span v-else>{{$t('ubtn')}}</span>
								</el-button>
							</div>
						</div>
					</div>
					<div class="account-cell">
						<div class="account-cell__hd">
							{{$t('uth1')}}
						</div>
						<div class="account-cell__bd flex_bd">
							<div class="account-btns">
								<el-row :gutter="10">
									<el-col :span="12">
										<el-button type="success" @click="showAdjustLeverage(1)">
											{{buyLeverage}}X{{$t('ulabel5')}}
										</el-button>
									</el-col>
									<el-col :span="12">
										<el-button type="danger" @click="showAdjustLeverage(2)">
											{{sellLeverage}}X{{$t('ulabel6')}}
										</el-button>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="u2-panel" style="background-color: unset;">
				<el-tabs v-model="activeName" type="card">
					<el-tab-pane :label="$t('uTab')" name="first">
						<div class="u2-content u2-tab" style="padding-bottom: 5px;">
							<el-tabs v-model="activeName2">
								<el-tab-pane :label="$t('dhTxt2')" name="1">
									<div class="u2-body" style="padding-bottom: 0;">
										<el-form ref="form" :model="form" class="u2-form" style="margin-bottom: 0;">
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('wth3')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form.entrustPrice"
															:controls="false">
														</el-input-number> -->
														<el-input type="number"
															:placeholder="$t('trplaceholder3')"
															v-model.number="form.entrustPrice"
															@input="entrustPriceChange">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{currentCoin.base}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('wtth4')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form.volume"
															:controls="false" :precision="0">
														</el-input-number> -->
														<el-input type="number"
															v-model.number="form.volume" :min="0">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-step">
													<div class="u2-step-inner">
														<el-slider v-model="form.sliderOpenPercent" :marks="marks"
															:step="25" show-stops :show-tooltip="false">
														</el-slider>
													</div>
												</div>
												<div class="u2-sheet">
													<div class="u2-sheet-item"><span
															class="text-green">{{$t('uTxt9')}}：</span>≈<span
															class="u2-sheet-value">
															{{avaOpenBuy}}
														</span>{{$t('zhang')}}
													</div>
													<div class="u2-sheet-item"><span
															class="text-red">{{$t('uTxt10')}}：</span>≈<span
															class="u2-sheet-value">
															{{avaOpenSell}}
														</span>{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
										</el-form>
									</div>
								</el-tab-pane>
								<el-tab-pane :label="$t('trTxt2')" name="0">
									<div class="u2-body" style="padding-bottom: 0;">
										<el-form ref="form" :model="form2" class="u2-form" style="margin-bottom: 0;">
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('uTxt11')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<el-input :placeholder="$t('uplaceholder2')"
															class="placeholder-right" readonly>
														</el-input>
													</div>
													<div class="u2-form-item__ft">

													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('wtth4')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form2.volume"
															:controls="false" :precision="0">
														</el-input-number> -->
														<el-input type="number"
															v-model.number="form2.volume" :min="0">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-step">
													<div class="u2-step-inner">
														<el-slider v-model="form2.sliderOpenPercent" :marks="marks"
															:step="25" show-stops :show-tooltip="false">
														</el-slider>
													</div>
												</div>
												<div class="u2-sheet">
													<div class="u2-sheet-item"><span
															class="text-green">{{$t('uTxt9')}}：</span>≈<span
															class="u2-sheet-value">
															{{avaOpenBuy}}
														</span>{{$t('zhang')}}
													</div>
													<div class="u2-sheet-item"><span
															class="text-red">{{$t('uTxt10')}}：</span>≈<span
															class="u2-sheet-value">
															{{avaOpenSell}}
														</span>{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
										</el-form>
									</div>
								</el-tab-pane>
								<el-tab-pane :label="$t('uTab3')" name="2">
									<div class="u2-body" style="padding-bottom: 0;">
										<el-form ref="form" :model="form3" class="u2-form" style="margin-bottom: 0;">
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('wth7')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form3.triggerPrice"
															:placeholder="$t('uplaceholder2')"
															:controls="false">
														</el-input-number> -->
														<el-input type="number"
															:placeholder="$t('uplaceholder2')"
															v-model.number="form3.triggerPrice"
															@input="triggerPriceChange">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{currentCoin.base}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('uTxt11')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form3.entrustPrice"
															:placeholder="$t('triggleplaceholder')"
															:controls="false">
														</el-input-number> -->
														<el-input type="number"
															:placeholder="$t('triggleplaceholder')"
															v-model.number="form3.entrustPrice"
															@input="entrustPriceChange3">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{currentCoin.base}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('wtth4')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form3.volume"
															:controls="false" :precision="0">
														</el-input-number> -->
														<el-input type="number"
															v-model.number="form3.volume" :min="0">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-sheet" style="margin-top: 0;">
													<div class="u2-sheet-item"><span
															class="text-green">{{$t('uTxt9')}}：</span>≈<span
															class="u2-sheet-value">
															{{avaOpenBuy}}
														</span>{{$t('zhang')}}
													</div>
													<div class="u2-sheet-item"><span
															class="text-red">{{$t('uTxt10')}}：</span>≈<span
															class="u2-sheet-value">
															{{avaOpenSell}}
														</span>{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
										</el-form>
									</div>
								</el-tab-pane>
							</el-tabs>
							<div class="u2-foot" v-if="isLogin">
								<el-button class="el-button--light" @click="onOpen(0)">{{$t('uTxt12')}}
								</el-button>
								<el-button type="danger" @click="onOpen(1)">{{$t('uTxt13')}}</el-button>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="$t('uTab2')" name="second">
						<div class="u2-content u2-tab" style="padding-bottom: 5px;">
							<el-tabs v-model="activeName2">
								<el-tab-pane :label="$t('dhTxt2')" name="1">
									<div class="u2-body" style="padding-bottom: 0;">
										<el-form ref="form" :model="form" class="u2-form" style="margin-bottom: 0;">
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('wth3')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form.entrustPrice"
															:controls="false">
														</el-input-number> -->
														<el-input type="number"
															:placeholder="$t('trplaceholder3')"
															v-model.number="form.entrustPrice"
															@input="entrustPriceChange">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{currentCoin.base}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('wtth4')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form.volume"
															:controls="false" :precision="0">
														</el-input-number> -->
														<el-input type="number"
															v-model.number="form.volume" :min="0">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-step">
													<div class="u2-step-inner">
														<el-slider v-model="form.sliderClosePercent" :marks="marks"
															:step="25" show-stops :show-tooltip="false">
														</el-slider>
													</div>
												</div>
												<div class="u2-sheet">
													<div class="u2-sheet-item"><span
															class="text-green">{{$t('uTxt17')}}：</span>≈<span
															class="u2-sheet-value">
															{{contractWalletInfo.coinBuyPosition}}
														</span>{{$t('zhang')}}
													</div>
													<div class="u2-sheet-item"><span
															class="text-red">{{$t('uTxt18')}}：</span>≈<span
															class="u2-sheet-value">
															{{contractWalletInfo.coinSellPosition}}
														</span>{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
										</el-form>
									</div>
								</el-tab-pane>
								<el-tab-pane :label="$t('trTxt2')" name="0">
									<div class="u2-body" style="padding-bottom: 0;">
										<el-form ref="form" :model="form2" class="u2-form" style="margin-bottom: 0;">
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('uTxt11')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<el-input :placeholder="$t('uplaceholder2')"
															class="placeholder-right" readonly>
														</el-input>
													</div>
													<div class="u2-form-item__ft">

													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('wtth4')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form2.volume"
															:controls="false" :precision="0">
														</el-input-number> -->
														<el-input type="number"
															v-model.number="form2.volume" :min="0">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-step">
													<div class="u2-step-inner">
														<el-slider v-model="form2.sliderClosePercent" :marks="marks"
															:step="25" show-stops :show-tooltip="false">
														</el-slider>
													</div>
												</div>
												<div class="u2-sheet">
													<div class="u2-sheet-item"><span
															class="text-green">{{$t('uTxt17')}}：</span>≈<span
															class="u2-sheet-value">
															{{contractWalletInfo.coinBuyPosition}}
														</span>{{$t('zhang')}}
													</div>
													<div class="u2-sheet-item"><span
															class="text-red">{{$t('uTxt18')}}：</span>≈<span
															class="u2-sheet-value">
															{{contractWalletInfo.coinSellPosition}}
														</span>{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
										</el-form>
									</div>
								</el-tab-pane>
								<el-tab-pane :label="$t('uTab3')" name="2">
									<div class="u2-body" style="padding-bottom: 0;">
										<el-form ref="form" :model="form3" class="u2-form" style="margin-bottom: 0;">
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('wth7')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form3.triggerPrice"
															:placeholder="$t('uplaceholder2')"
															:controls="false">
														</el-input-number> -->
														<el-input type="number"
															:placeholder="$t('uplaceholder2')"
															v-model.number="form3.triggerPrice"
															@input="triggerPriceChange">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{currentCoin.base}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('uTxt11')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form3.entrustPrice"
															:placeholder="$t('triggleplaceholder')"
															:controls="false">
														</el-input-number> -->
														<el-input type="number"
															:placeholder="$t('triggleplaceholder')"
															v-model.number="form3.entrustPrice"
															@input="entrustPriceChange3">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{currentCoin.base}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-form-item">
													<div class="u2-form-item__hd">
														{{$t('wtth4')}}
													</div>
													<div class="u2-form-item__bd flex_bd">
														<!-- <el-input-number v-model="form3.volume"
															:controls="false" :precision="0">
														</el-input-number> -->
														<el-input type="number"
															v-model.number="form3.volume" :min="0">
														</el-input>
													</div>
													<div class="u2-form-item__ft">
														{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="u2-sheet" style="margin-top: 0;">
													<div class="u2-sheet-item"><span
															class="text-green">{{$t('uTxt17')}}：</span>≈<span
															class="u2-sheet-value">
															{{contractWalletInfo.coinBuyPosition}}
														</span>{{$t('zhang')}}
													</div>
													<div class="u2-sheet-item"><span
															class="text-red">{{$t('uTxt18')}}：</span>≈<span
															class="u2-sheet-value">
															{{contractWalletInfo.coinSellPosition}}
														</span>{{$t('zhang')}}
													</div>
												</div>
											</el-form-item>
										</el-form>
									</div>
								</el-tab-pane>
							</el-tabs>
							<div class="u2-foot" v-if="isLogin">
								<el-button class="el-button--light" @click="onClose(0)">{{$t('uTxt12')}}
								</el-button>
								<el-button type="danger" @click="onClose(1)">{{$t('uTxt13')}}</el-button>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
				<div class="u2-panel__foot">
					<div class="u2-foot" v-if="!isLogin">
						<router-link to="/register" class="el-button el-button--warning">{{$t('ureg')}}
						</router-link>
						<router-link to="/login" class="el-button el-button--info">{{$t('sign')}}
						</router-link>
					</div>
				</div>
			</div>
		</el-drawer>
		<!-- 移动端 -->
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	// TODO:1
	import TV from "@/components/tv";
	import {
		symbolThumb,
		symbolInfo,
		orderHistory,
		orderCurrent,
		latestTrade,
		walletDetail,
		plateMini,
		orderCancel,
		orderOpen,
		orderClose,
		switchPattern,
		modifyLeverage,
		plateFull
	} from '@/api/api/coinswap'
	import SockJS from 'sockjs-client';
	import Stomp from 'stompjs';
	import Head from '@/components/Head.vue'

	export default {
		name: 'Home',
			// TODO:2
		components: {
			Head,
			TV
		},
		data() {
			return {
				drawer: false,
				tableData: [],
				mode: '',
				activeName: 'first',
				price: '1977.5.350',
				number: '',
				activeName2: '1',
				activeName3: 'firsth',
				currentPositionList: [],
				type: 'week',
				newPrice: '',
				dialogVisible: false,
				dialogVisible2: false,
				dialogVisible3: false,
				dialogVisible4: false,
				from: '',
				to: '',
				marks: {
					0: '0%',
					25: '25%',
					50: '50%',
					75: '75%',
					100: '100%'
				},
				radio: '1',
				symbol: '',
				options: [],
				tableData3: [],
				tableData4: [],
				resolution: '5',
				resolution2: '',
				time: new Date(),
				info: {},
				currentCoin: {
					coin: '',
					base: ''
				},
				plate: {
					maxPostion: 10,
					askRows: [],
					bidRows: []
				},
				coinScale: 6,
				baseCoinScale: 6,
				activeName5: "all",
				isLogin: false,
				orderId: null,
				datafeed: null,
				current: 1,
				form: {
					entrustPrice: '',
					volume: '',
					sliderOpenPercent: 0,
					sliderClosePercent: 0
				},
				form2: {
					volume: '',
					sliderOpenPercent: 0,
					sliderClosePercent: 0
				},
				form3: {
					triggerPrice: '',
					entrustPrice: '',
					volume: '',
				},
				contractWalletInfo: {
					coinBalance: 0.0,
					coinBuyPosition: 0.0,
					coinSellPosition: 0.0,
					coinBuyLeverage: 10,
					coinSellLeverage: 10,
					coinFrozenBalance: 0.0,
					coinBuyPrincipalAmount: 0.0,
					coinSellPrincipalAmount: 0.0,
					usdtBalance: 0.0
				},
				buyLeverage: 10,
				sellLeverage: 10,
				leverageList: [],
				leverageType: 1,
				changeLeverageType: 1,
				minLeverage: 1,
				maxLeverage: 100,
				screenWidth: null,
				smCurrent: 1
			};
		},
		computed: {
			profitAndLoss: function() {
				if (!this.isLogin || this.contractWalletInfo == null) {
					return 0;
				}
				var buyPL = 0;
				if (this.contractWalletInfo.coinBuyPrice > 0) {
					buyPL = (this.currentCoin.close / this.contractWalletInfo.coinBuyPrice - 1) * (this
							.contractWalletInfo.coinBuyPosition + this.contractWalletInfo.coinFrozenBuyPosition) * this
						.contractWalletInfo.coinShareNumber;
				}
				var sellPL = 0;
				if (this.contractWalletInfo.coinSellPrice > 0) {
					sellPL = (1 - this.currentCoin.close / this.contractWalletInfo.coinSellPrice) * (this
							.contractWalletInfo.coinSellPosition + this.contractWalletInfo.coinFrozenSellPosition) *
						this.contractWalletInfo.coinShareNumber;
				}

				return (buyPL + sellPL);
			},
			totalQuanyi: function() {
				if (!this.isLogin || this.contractWalletInfo == null) {
					return 0;
				}
				var buyPL = 0;
				if (this.contractWalletInfo.coinBuyPrice > 0) {
					buyPL = (this.currentCoin.close / this.contractWalletInfo.coinBuyPrice - 1) * (this
							.contractWalletInfo.coinBuyPosition + this.contractWalletInfo.coinFrozenBuyPosition) * this
						.contractWalletInfo.coinShareNumber;
				}
				var sellPL = 0;
				if (this.contractWalletInfo.coinSellPrice > 0) {
					sellPL = (1 - this.currentCoin.close / this.contractWalletInfo.coinSellPrice) * (this
							.contractWalletInfo.coinSellPosition + this.contractWalletInfo.coinFrozenSellPosition) *
						this.contractWalletInfo.coinShareNumber;
				}

				return (buyPL + sellPL + this.contractWalletInfo.coinBalance + this.contractWalletInfo
					.coinFrozenBalance + this.contractWalletInfo.coinBuyPrincipalAmount + this.contractWalletInfo
					.coinSellPrincipalAmount);
			},
			assetRate: function() {
				if (!this.isLogin || this.contractWalletInfo == null) return 0;
				var zhanyong = this.contractWalletInfo.coinFrozenBalance + this.contractWalletInfo
					.coinBuyPrincipalAmount + this.contractWalletInfo.coinSellPrincipalAmount;
				if (this.totalQuanyi > 0) {
					return zhanyong / this.totalQuanyi;
				} else {
					return 0;
				}
			},
			avaOpenBuy: function() { // 可开多
				if (!this.isLogin || this.contractWalletInfo == null) return 0;

				let price = this.currentCoin.close;
				if (this.entrustOrderType == 1) {
					price = this.form.entrustPrice;
				}
				// 计算可开多/空
				if (this.contractWalletInfo.coinPattern == "FIXED") { // 逐仓，直接coin余额即可
					return parseInt(this.buyLeverage * this.contractWalletInfo.coinBalance * price / (this
						.info.shareNumber));
				} else { // 全仓模式
					// 计算多仓盈亏
					var buyPL = 0;
					if (this.contractWalletInfo.coinBuyPrice > 0) {
						// 多仓盈亏（当前价格 / 开仓均价 - 1）* （可用多单仓位 + 冻结多单仓位） * 合约面值
						buyPL = (price / this.contractWalletInfo.coinBuyPrice - 1) * (this.contractWalletInfo
								.coinBuyPosition + this.contractWalletInfo.coinFrozenBuyPosition) * this
							.contractWalletInfo.coinShareNumber;

					}
					// 计算空仓盈亏
					var sellPL = 0;
					if (this.contractWalletInfo.coinSellPrice > 0) {
						// 空仓盈亏（1 - 当前价格 / 开仓均价）* （可用空单仓位 + 冻结空单仓位） * 合约面值
						sellPL = (1 - price / this.contractWalletInfo.coinSellPrice) * (this.contractWalletInfo
								.coinSellPosition + this.contractWalletInfo.coinFrozenSellPosition) * this
							.contractWalletInfo.coinShareNumber;
					}
					// 计算盈亏（多仓盈亏 + 空仓盈亏 + 多仓保证金 + 空仓保证金）
					var lossandp = (buyPL + sellPL) / price + (this.contractWalletInfo.coinBuyPrincipalAmount + this
						.contractWalletInfo.coinSellPrincipalAmount);
					// 大于0，说明多单和空单还没亏完，直接用可用余额计算能开多少单（计算方法 = 多仓杠杆 * 可用余额 / 合约面值）
					if (lossandp >= 0) {
						return parseInt(this.buyLeverage * this.contractWalletInfo.coinBalance * price / this
							.info.shareNumber);
					} else {
						// 小于0，因为是全仓，所以需要考虑盈亏问题，计算方法（多仓杠杆 *（可用余额 - 亏损金额）/ 合约面值）
						return parseInt(this.buyLeverage * (this.contractWalletInfo.coinBalance + lossandp) * price /
							this.info.shareNumber);
					}
				}
			},
			avaOpenSell: function() { // 可开空
				if (!this.isLogin || this.contractWalletInfo == null) return 0;
				let price = this.currentCoin.close;
				if (this.entrustOrderType == 1) {
					price = this.form.entrustPrice;
				}
				// 计算可开多/空
				if (this.contractWalletInfo.coinPattern == "FIXED") { // 逐仓，直接coin余额即可
					return parseInt(this.sellLeverage * this.contractWalletInfo.coinBalance * price / this
						.info.shareNumber);
				} else {
					// 计算可用
					var buyPL = 0;
					if (this.contractWalletInfo.coinBuyPrice > 0) {
						// buyPL = (this.currentCoin.close - this.contractWalletInfo.coinBuyPrice) * (this.contractWalletInfo.coinBuyPosition + this.contractWalletInfo.coinFrozenBuyPosition) * this.contractWalletInfo.coinShareNumber;
						// buyPL = (price / this.contractWalletInfo.coinBuyPrice -1) * (this.contractWalletInfo.coinBuyPosition + this.contractWalletInfo.coinFrozenBuyPosition) * this.contractWalletInfo.coinShareNumber * this.buyLeverage;
						buyPL = (price / this.contractWalletInfo.coinBuyPrice - 1) * (this.contractWalletInfo
								.coinBuyPosition + this.contractWalletInfo.coinFrozenBuyPosition) * this
							.contractWalletInfo.coinShareNumber;
					}
					var sellPL = 0;
					if (this.contractWalletInfo.coinSellPrice > 0) {
						// sellPL = (this.contractWalletInfo.coinSellPrice - this.currentCoin.close) * (this.contractWalletInfo.coinSellPosition + this.contractWalletInfo.coinFrozenSellPosition) * this.contractWalletInfo.coinShareNumber;
						sellPL = (1 - price / this.contractWalletInfo.coinSellPrice) * (this.contractWalletInfo
								.coinSellPosition + this.contractWalletInfo.coinFrozenSellPosition) * this
							.contractWalletInfo.coinShareNumber;
					}
					var lossandp = (buyPL + sellPL) / price + this.contractWalletInfo.coinBuyPrincipalAmount + this
						.contractWalletInfo.coinSellPrincipalAmount;
					if (lossandp >= 0) {
						return parseInt(this.sellLeverage * this.contractWalletInfo.coinBalance * price / this
							.info.shareNumber);
					} else {
						return parseInt(this.sellLeverage * (this.contractWalletInfo.coinBalance + lossandp) * price /
							this.info.shareNumber);
					}
				}
			},
			positionList: function() {
				if (this.contractWalletInfo.id == 0 || this.currentPositionList.length == 0) {
					return;
				}
				var buyPl = 0;
				// 构建多仓数据
				if (this.contractWalletInfo.coinBuyPosition > 0 || this.contractWalletInfo.coinFrozenBuyPosition > 0) {
					buyPl = (this.currentCoin.close - this.contractWalletInfo.coinBuyPrice) * (this.contractWalletInfo
							.coinBuyPosition + this.contractWalletInfo.coinFrozenBuyPosition) * this.contractWalletInfo
						.coinShareNumber / this.contractWalletInfo.coinBuyPrice;
					this.$set(this.currentPositionList[0], "direction", "Buy");
					this.$set(this.currentPositionList[0], "openPrice", this.contractWalletInfo.coinBuyPrice);
					this.$set(this.currentPositionList[0], "pl", buyPl);
					this.$set(this.currentPositionList[0], "position", this.contractWalletInfo.coinBuyPosition + this
						.contractWalletInfo.coinFrozenBuyPosition);
					this.$set(this.currentPositionList[0], "avaPosition", this.contractWalletInfo.coinBuyPosition);
					this.$set(this.currentPositionList[0], "margin", this.contractWalletInfo.coinBuyPrincipalAmount);
					this.$set(this.currentPositionList[0], "leverage", this.contractWalletInfo.coinBuyLeverage);
					this.$set(this.currentPositionList[0], "plRatio", (buyPl / this.currentCoin.close) / this
						.contractWalletInfo.coinBuyPrincipalAmount);
					this.$set(this.currentPositionList[0], "forcePrice", this.contractWalletInfo.buyForcePrice);
					if (this.contractWalletInfo.coinPattern == "FIXED") {
						this.$set(this.currentPositionList[0], "mRatio", ((buyPl / this.currentCoin.close) + this
								.contractWalletInfo.coinBuyPrincipalAmount) / this.contractWalletInfo
							.coinBuyPrincipalAmount / this.contractWalletInfo.coinBuyLeverage);
					} else {
						this.$set(this.currentPositionList[0], "mRatio", ((buyPl / this.currentCoin.close) + this
								.contractWalletInfo.coinBuyPrincipalAmount + this.contractWalletInfo.coinBalance) /
							this.contractWalletInfo.coinBuyPrincipalAmount / this.contractWalletInfo
							.coinBuyLeverage);
					}
					this.$set(this.currentPositionList[0], "cmRatio", this.info.maintenanceMarginRate);

					if (this.contractWalletInfo.coinPattern == "FIXED") {
						let checkBuyCount = 0
						if (buyPl + this.contractWalletInfo.coinBuyPrincipalAmount < 0 && checkBuyCount < 5) {
							this.getMemberContractWallet();
							checkBuyCount++;
						}
					}
				}
				// 构建空仓数据
				var sellPl = 0;
				if (this.contractWalletInfo.coinSellPosition > 0 || this.contractWalletInfo.coinFrozenSellPosition >
					0) {
					sellPl = (1 - this.currentCoin.close / this.contractWalletInfo.coinSellPrice) * (this
							.contractWalletInfo.coinSellPosition + this.contractWalletInfo.coinFrozenSellPosition) *
						this.contractWalletInfo.coinShareNumber;
					var temIndex = 0;
					if (this.currentPositionList.length > 1) {
						temIndex = 1;
					}
					this.$set(this.currentPositionList[temIndex], "direction", "Sell");
					this.$set(this.currentPositionList[temIndex], "openPrice", this.contractWalletInfo.coinSellPrice);
					this.$set(this.currentPositionList[temIndex], "pl", sellPl);
					this.$set(this.currentPositionList[temIndex], "position", this.contractWalletInfo
						.coinSellPosition + this.contractWalletInfo.coinFrozenSellPosition);
					this.$set(this.currentPositionList[temIndex], "avaPosition", this.contractWalletInfo
						.coinSellPosition);
					this.$set(this.currentPositionList[temIndex], "margin", this.contractWalletInfo
						.coinSellPrincipalAmount);
					this.$set(this.currentPositionList[temIndex], "leverage", this.contractWalletInfo
						.coinSellLeverage);
					this.$set(this.currentPositionList[temIndex], "plRatio", (sellPl / this.currentCoin.close) / this
						.contractWalletInfo.coinSellPrincipalAmount);
					if (this.contractWalletInfo.coinPattern == "FIXED") {
						this.$set(this.currentPositionList[temIndex], "mRatio", (sellPl / this.currentCoin.close + this
								.contractWalletInfo.coinSellPrincipalAmount) / this.contractWalletInfo
							.coinSellPrincipalAmount / this.contractWalletInfo.coinSellLeverage);
					} else {
						this.$set(this.currentPositionList[temIndex], "mRatio", (sellPl / this.currentCoin.close + this
								.contractWalletInfo.coinSellPrincipalAmount + this.contractWalletInfo.coinBalance
							) / this.contractWalletInfo.coinSellPrincipalAmount / this.contractWalletInfo
							.coinSellLeverage);
					}
					this.$set(this.currentPositionList[temIndex], "cmRatio", this.info
						.maintenanceMarginRate);
					this.$set(this.currentPositionList[temIndex], "forcePrice", this.contractWalletInfo
						.sellForcePrice);

					if (this.contractWalletInfo.coinPattern == "FIXED") {
						let checkSellCount = 0
						if (sellPl + this.contractWalletInfo.coinSellPrincipalAmount <= 0 && checkSellCount < 5) {
							this.getMemberContractWallet();
							checkSellCount++;
						}
					}
				}
				// 可能全仓模式已爆仓
				if (this.contractWalletInfo.coinPattern == "CROSSED") {
					let checkAllCount = 0
					if (buyPl + sellPl + this.contractWalletInfo.coinBalance + this.contractWalletInfo
						.coinFrozenBalance + this.contractWalletInfo.coinBuyPrincipalAmount + this.contractWalletInfo
						.coinSellPrincipalAmount < 0 && checkAllCount < 5) {
						this.getMemberContractWallet();
						checkAllCount++;
					}
				}
				return this.currentPositionList;
			}
		},
		beforeDestroy() {

		},
		watch: {
			$route() {
				this.init();
			},
			screenWidth: function(n, o) {
				if (n <= 768) {
					console.log(o)
				} else {
					this.smCurrent = 1
				}
			}
		},
		filters: {
			fixed4: function(value) {
				if (value) {
					return value.toFixed(4);
				}
			},
			percent: function(value) {
				return (value * 100).toFixed(2);
			}
		},
		created() {
			this.isLogin = this.$store.state.isLogin
		},
		mounted() {
			this.init()
			this.screenWidth = document.body.clientWidth
			window.onresize = () => {
				return (() => {
					this.screenWidth = document.body.clientWidth
				})
			}
		},
		methods: {
			init() {
				var str = this.$route.params.id.toUpperCase()
				var arr = str.split('_')
				var symbol = arr[0] + '/' + arr[1]
				this.symbol = symbol
				this.currentCoin.coin = arr[0]
				this.currentCoin.base = arr[1]
				this.getSymbol()
				this.getTrade()
				this.getPlate()
				this.getSymbolScale()
			},
			successCallback(that, stompClient) {
								// TODO:5
				that.$refs.tv.getKline(
					{
						a:that.host + "/coinswap",
						b:that.currentCoin,
						c:stompClient,
						d:that.baseCoinScale
					}
				);
				stompClient.subscribe("/topic/swap/thumb", function(msg) {
					var resp = JSON.parse(msg.body);
					if (that.currentCoin != null && that.currentCoin.symbol == resp.symbol) {
						that.currentCoin.close = resp.close;
						that.currentCoin.rose =
							resp.chg > 0 ?
							"+" + (resp.chg * 100).toFixed(2) + "%" :
							(resp.chg * 100).toFixed(2) + "%";
						that.currentCoin.close = resp.close;
						that.currentCoin.high = resp.high;
						that.currentCoin.low = resp.low;
						that.currentCoin.turnover = parseInt(resp.volume);
						that.currentCoin.volume = resp.volume;
						that.currentCoin.usdRate = resp.usdRate;
					}
				});


				stompClient.subscribe('/topic/swap/trade/' + that.currentCoin.symbol, function(msg) {
					var resp = JSON.parse(msg.body)
					if (resp.length > 0) {
						for (var i = 0; i < resp.length; i++) {
							that.tableData.unshift(resp[i])
						}
					}
					if (that.tableData.length > 20) {
						that.tableData = that.tableData.slice(0, 20)
					}
				})

				stompClient.subscribe('/topic/swap/trade-plate/' + that.currentCoin.symbol, function(
					msg) {
					var resp = JSON.parse(msg.body)
					if (resp.direction == 'SELL') {
						var asks = resp.items
						that.plate.askRows = []
						let totle = 0
						for (let i = that.plate.maxPostion - 1; i >= 0; i--) {
							var ask = {}
							if (i < asks.length) {
								ask = asks[i]
							} else {
								ask['price'] = 0
								ask['amount'] = 0
							}
							ask.direction = 'SELL'
							ask.position = i + 1
							that.plate.askRows.push(ask)
						}
						for (let i = that.plate.askRows.length - 1; i >= 0; i--) {
							if (i == that.plate.askRows.length - 1 || that.plate.askRows[i]
								.price == 0) {
								that.plate.askRows[i].totalAmount = that.plate.askRows[i].amount
							} else {
								that.plate.askRows[i].totalAmount = that.plate.askRows[i + 1]
									.totalAmount + that.plate.askRows[i].amount
							}
							totle += that.plate.askRows[i].amount
						}
						that.plate.askTotle = totle
					} else {
						var bids = resp.items
						that.plate.bidRows = []
						let totle = 0
						for (let i = 0; i < that.plate.maxPostion; i++) {
							var bid = {}
							if (i < bids.length) {
								bid = bids[i]
							} else {
								bid['price'] = 0
								bid['amount'] = 0
							}
							bid.direction = 'BUY'
							bid.position = i + 1
							that.plate.bidRows.push(bid)
						}
						for (let i = 0; i < that.plate.bidRows.length; i++) {
							if (i == 0 || that.plate.bidRows[i].amount == 0) {
								that.plate.bidRows[i].totalAmount = that.plate.bidRows[i].amount
							} else {
								that.plate.bidRows[i].totalAmount = that.plate.bidRows[i - 1]
									.totalAmount + that.plate.bidRows[i].amount
							}
							totle += that.plate.bidRows[i].amount
						}
						that.plate.bidTotle = totle
					}

					if (that.current == 2) {
						that.getPlateFull()
					}
				})
			},


      startWebsocket(){
        if (this.stompClient) {
          this.stompClient.ws.close();
        }

        this.stompClient = this.createStompClient();

        this.stompClient.connect({},
            frame => {
              console.log(frame);
              this.successCallback(this, this.stompClient);
            },
            () => {
              console.log("Connection error....");
              this.reconnect();
            }
        );
      },
      createStompClient() {
        let socket = new SockJS(this.host + '/coinswap/swap-ws');
        let stompClient = Stomp.over(socket);

        stompClient.debug = false;

        socket.onclose = () => {
          console.log('Connection closed');
          this.reconnect();
        };

        socket.onerror = error => {
          console.error('Error occurred: ', error);
          this.reconnect();
        };

        return stompClient;
      },

      // Use setTimeout for reconnect attempts instead of setInterval
      reconnect() {
        console.log('Attempting to reconnect in 2s...');

        setTimeout(() => {
          if (this.stompClient.ws.readyState === WebSocket.OPEN) {
            console.log("Connection is already open, no need to reconnect");
            this.successCallback(this, this.stompClient);
          } else {
            console.log('Reconnecting...');
            this.startWebsocket();
          }
        }, 2000);
      },
			getSymbol() {
				symbolThumb().then(res => {
					var arr = []
					res.forEach(item => {
						item.chg = this.$math.format(
							this.$math.multiply(
								this.$math.bignumber(100), this.$math.bignumber(item.chg)
							)
						)
						item.feePercent = this.$math.format(
							this.$math.multiply(
								this.$math.bignumber(100), this.$math.bignumber(item
									.feePercent)
							)
						)
						return arr.push(item)
					})
					this.options = res
					var coinInfo = this.options.filter(item => {
						return item.symbol == this.symbol
					})
					this.currentCoin = coinInfo[0]
					this.currentCoin.rose = coinInfo[0].chg > 0 ? '+' + coinInfo[0].chg + '%' : coinInfo[0]
						.chg +
						'%';
					this.currentCoin.coin = coinInfo[0].symbol.split('/')[0]
					this.currentCoin.base = coinInfo[0].symbol.split('/')[1]

					this.form.entrustPrice = coinInfo[0].close;

					this.getPlateFull()
					this.startWebsocket();
				})
			},
			getPlateFull() {
				plateFull({
					symbol: this.symbol
				}).then(res => {
					this.full = res
					if (this.current == 2) {
						var arr1 = []
						var arr2 = []
						var total = 0
						var total2 = 0
						res.ask.items.forEach(item => {
							total += item.amount
							let obj = {
								value: item.price,
								total: total
							}
							arr1.push(obj)
						})
						res.bid.items.forEach(item => {
							total2 += item.amount
							let obj = {
								value: item.price,
								total: total2
							}
							arr2.push(obj)
						})
						let max = Math.max.apply(Math, arr1);
						let min = Math.min.apply(Math, arr1);
						let max2 = Math.max.apply(Math, arr2);
						let min2 = Math.min.apply(Math, arr2);
						this.linechart(arr2, max2, min2);
						this.linechart2(arr1, max, min);
					}
				})
			},
			linechart(arr, max, min) {
				var label2 = this.$t('deptotal')
				var label = this.$t('uTxt11')
				var myChart = echarts.init(document.getElementById('depth'))
				var options = {
					tooltip: {
						trigger: 'axis',
						formatter: function(params) {
							return label + ' ' + params[0].data.value + '<br />' + label2 + ' ' +
								params[0].data.total
						},
						axisPointer: {
							type: 'none'
						}
					},
					grid: {
						left: '0%',
						right: '0%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						axisTick: {
							show: false
						},
						axisLine: {
							lineStyle: {
								color: '#ffffff',
							}
						},
						axisLabel: {
							show: false
						},
						show: false
					}],
					yAxis: [{
						type: 'value',
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							inside: true,
							verticalAlign: 'bottom',
						},
						show: false,
						max: max,
						min: min
					}],
					series: [{
						name: 'SELL',
						type: 'line',
						// smooth: true,
						showSymbol: false,
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.2)',
							width: 0
						},
						areaStyle: {
							color: 'rgba(77, 184, 114, 0.4)',
						},
						symbol: 'none',
						label: {
							show: false
						},
						data: arr
					}]
				};
				myChart.setOption(options)
				window.addEventListener('resize', () => {
					myChart.resize()
				})
			},
			linechart2(arr, max, min) {
				var label2 = this.$t('deptotal')
				var label = this.$t('uTxt11')
				var myChart2 = echarts.init(document.getElementById('depth2'))
				var options2 = {
					tooltip: {
						trigger: 'axis',
						formatter: function(params) {
							return label + ' ' + params[0].data.value + '<br />' + label2 + ' ' +
								params[0].data.total
						},
						axisPointer: {
							type: 'none'
						}
					},
					grid: {
						left: '0%',
						right: '0%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						axisTick: {
							show: false
						},
						axisLine: {
							lineStyle: {
								color: '#ffffff',
							}
						},
						axisLabel: {
							show: false
						},
						show: false
					}],
					yAxis: [{
						type: 'value',
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							inside: true,
							verticalAlign: 'bottom',
						},
						show: false,
						max: max,
						min: min
					}],
					series: [{
						name: 'buy',
						type: 'line',
						// smooth: true,
						showSymbol: false,
						areaStyle: {
							color: 'rgba(246, 70, 93, 0.3)',
						},
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.2)',
							width: 0
						},
						symbol: 'none',
						label: {
							show: false
						},
						data: arr
					}]
				};
				myChart2.setOption(options2)
				window.addEventListener('resize', () => {
					myChart2.resize()
				})
			},
			getTrade() {
				latestTrade({
					symbol: this.symbol,
					size: 20
				}).then(res => {
					this.tableData = res.slice(0, 20)
				})
			},
			getPlate(str = "") {
				plateMini({
					symbol: this.symbol
				}).then(res => {
					this.plate.askRows = []
					this.plate.bidRows = []
					let data = res
					if (data.ask && data.ask.items) {
						for (let i = 0; i < data.ask.items.length; i++) {
							if (i == 0) {
								data.ask.items[i].totalAmount = data.ask.items[i].amount
							} else {
								data.ask.items[i].totalAmount = data.ask.items[i - 1].totalAmount + data
									.ask.items[
										i].amount
							}
						}
						if (data.ask.items.length >= this.plate.maxPostion) {
							for (let i = this.plate.maxPostion; i > 0; i--) {
								let ask = data.ask.items[i - 1]
								ask.direction = 'SELL'
								ask.position = i
								this.plate.askRows.push(ask)
								let rows = this.plate.askRows,
									totle = rows[0].totalAmount
								this.plate.askTotle = totle
							}
						} else {
							for (let i = this.plate.maxPostion; i > data.ask.items.length; i--) {
								let ask = {
									price: 0,
									amount: 0
								}
								ask.direction = 'SELL'
								ask.position = i
								ask.totalAmount = ask.amount
								this.plate.askRows.push(ask)

							}
							for (let i = data.ask.items.length; i > 0; i--) {
								let ask = data.ask.items[i - 1]
								ask.direction = 'SELL'
								ask.position = i
								this.plate.askRows.push(ask)
								let askItemIndex = data.ask.items.length - 1 > 0 ? data.ask.items.length -
									1 : 0
								let rows = this.plate.askRows,
									totle = rows[askItemIndex].totalAmount
								this.plate.askTotle = totle
							}
						}
					}
					if (data.bid && data.bid.items) {
						for (let i = 0; i < data.bid.items.length; i++) {
							if (i == 0) data.bid.items[i].totalAmount = data.bid.items[i].amount
							else data.bid.items[i].totalAmount = data.bid.items[i - 1].totalAmount + data
								.bid
								.items[i].amount
						}
						for (let i = 0; i < data.bid.items.length; i++) {
							let bid = data.bid.items[i]
							bid.direction = 'BUY'
							bid.position = i + 1
							this.plate.bidRows.push(bid)
							if (i == this.plate.maxPostion - 1) break
						}
						if (data.bid.items.length < this.plate.maxPostion) {
							for (let i = data.bid.items.length; i < this.plate.maxPostion; i++) {
								let bid = {
									price: 0,
									amount: 0
								}
								bid.direction = 'BUY'
								bid.position = i + 1
								bid.totalAmount = 0
								this.plate.bidRows.push(bid)
							}
							let bidItemIndex = data.bid.items.length - 1 > 0 ? data.bid.items.length - 1 :
								0
							let rows = this.plate.bidRows,
								totle = rows[bidItemIndex].totalAmount
							this.plate.bidTotle = totle
						} else {
							let rows = this.plate.bidRows,
								len = rows.length,
								totle = rows[len - 1].totalAmount
							this.plate.bidTotle = totle
						}
					}
					if (str != "") {
						this.activeName5 = str;
					}
				})
			},
      handleOrderClick(tab) {
        this.getMemberContractWallet();

      },
			getSymbolScale() {
				symbolInfo({
					symbol: this.symbol
				}).then(res => {
					this.info = res
					this.baseCoinScale = res.baseCoinScale
					this.coinScale = res.coinScale

					if (this.info.leverageType == 1) {
						this.leverageList = this.info.leverage.split(",");
						this.minLeverage == this.leverageList[0]
						this.maxLeverage == this.leverageList.slice(-1)
					}


					if (this.isLogin) {
						this.getMemberContractWallet(); // 获取用户合约资产信息
					}
				})
			},
			getMemberContractWallet() {
				walletDetail({
					contractCoinId: this.info.id
				}).then(res => {
					var resp = res;
					if (resp != null) {
						this.contractWalletInfo = resp.data;
						if (this.contractWalletInfo == null) {
							return;
						}
            this.radio = this.contractWalletInfo.coinPattern;
						if (this.contractWalletInfo.coinPattern == "FIXED") {
							this.marginMode = "1";
						} else {
							this.marginMode = "0";
						}
						this.buyLeverage = this.contractWalletInfo.coinBuyLeverage + "";
						this.sellLeverage = this.contractWalletInfo.coinSellLeverage + "";
						// 默认杠杆如不在范围内
						var levArr = this.info.leverage.split(",");
						if (Number(this.buyLeverage) < Number(levArr[0])) {
							this.buyLeverage = levArr[0];
						}
						if (Number(this.sellLeverage) < Number(levArr[0])) {
							this.sellLeverage = levArr[0];
						}
						if (Number(this.buyLeverage) > Number(levArr[levArr.length - 1])) {
							this.buyLeverage = levArr[levArr.length - 1];
						}
						if (Number(this.sellLeverage) > Number(levArr[levArr.length - 1])) {
							this.sellLeverage = levArr[levArr.length - 1];
						}

						// 构建多仓数据
						if (this.contractWalletInfo.coinBuyPosition > 0 || this.contractWalletInfo
							.coinFrozenBuyPosition > 0) {
							var buyPL = (this.currentCoin.close - this.contractWalletInfo.coinBuyPrice) * (this
									.contractWalletInfo.coinBuyPosition + this.contractWalletInfo
									.coinFrozenBuyPosition) * this.contractWalletInfo.coinShareNumber / this
								.contractWalletInfo.coinBuyPrice;
							let tItem = {
								direction: "Buy",
								openPrice: this.contractWalletInfo.coinBuyPrice, // 开仓价格
								pl: buyPL, // 盈亏
								position: this.contractWalletInfo.coinBuyPosition + this.contractWalletInfo
									.coinFrozenBuyPosition, // 总仓位
								avaPosition: this.contractWalletInfo.coinBuyPosition, // 可用仓位
								margin: this.contractWalletInfo.coinBuyPrincipalAmount, // 保证金
								leverage: this.contractWalletInfo.coinBuyLeverage, // 多仓杠杆
								plRatio: buyPL / this.currentCoin.close / this.contractWalletInfo
									.coinBuyPrincipalAmount,
								mRatio: (buyPL / this.currentCoin.close + this.contractWalletInfo
										.coinBuyPrincipalAmount) / this.contractWalletInfo
									.coinBuyPrincipalAmount / this.contractWalletInfo.coinBuyLeverage,
								cmRatio: this.info.maintenanceMarginRate
							};
							this.currentPositionList.splice(1);
							this.$set(this.currentPositionList, 0, tItem);
						} else {
							this.currentPositionList.splice(0, 1);
						}

						if (this.contractWalletInfo.coinSellPosition > 0 || this.contractWalletInfo
							.coinFrozenSellPosition > 0) {
							// 构建空仓数据

							var sellPL = (this.contractWalletInfo.coinSellPrice - this.currentCoin.close) * (this
									.contractWalletInfo.coinSellPosition + this.contractWalletInfo
									.coinFrozenSellPosition) * this.contractWalletInfo.coinShareNumber / this
								.contractWalletInfo.coinSellPrice;

							let tItem = {
								direction: "Sell",
								openPrice: this.contractWalletInfo.coinSellPrice, // 开仓价格
								pl: sellPL, // 盈亏
								position: this.contractWalletInfo.coinSellPosition + this.contractWalletInfo
									.coinFrozenSellPosition, // 总仓位
								avaPosition: this.contractWalletInfo.coinSellPosition, // 可用仓位
								margin: this.contractWalletInfo.coinSellPrincipalAmount, // 保证金
								leverage: this.contractWalletInfo.coinSellLeverage, // 多仓杠杆
								plRatio: this.contractWalletInfo.coinTotalProfitAndLoss / this
									.contractWalletInfo.coinSellPrincipalAmount,
								mRatio: (sellPL / this.currentCoin.close + this.contractWalletInfo
										.coinSellPrincipalAmount) / this.contractWalletInfo
									.coinSellPrincipalAmount / this.contractWalletInfo.coinSellLeverage,
								cmRatio: this.info.maintenanceMarginRate
							};

							if (this.currentPositionList.length == 1) {
								this.$set(this.currentPositionList, 1, tItem);
							} else {
								this.$set(this.currentPositionList, 0, tItem);
							}
						} else {
							this.currentPositionList.splice(1, 1);
						}

						this.getCurrentEntrustOrders();
						this.getHistoryEntrustOrders();
					}
				})
			},
			handleClick(tab) {
				var str = tab.name
				if (str == 'all') {
					this.plate.maxPostion = 10
				} else {
					this.plate.maxPostion = 20
				}
				this.getPlate(str)
			},
			getCurrentEntrustOrders() {
				if (this.isLogin) {
					orderCurrent({
						contractCoinId: this.info.id,
						pageNo: 1,
						pageSize: 50
					}).then(res => {
						this.tableData3 = res.content
					})
				}
			},
			getHistoryEntrustOrders() {
				if (this.isLogin) {
					orderHistory({
						contractCoinId: this.info.id,
						pageNo: 1,
						pageSize: 50
					}).then(res => {
						this.tableData4 = res.content
					})
				}
			},
			handleCancel(orderId) {
				this.orderId = orderId
				this.dialogVisible4 = true
			},
			orderConfirm() {
				orderCancel({
					entrustId: this.orderId
				}).then(res => {
					console.log(res)
					if (res.code == 0) {
            this.getMemberContractWallet();
						this.$message({
							message: '撤单成功',
							type: 'success'
						});
						this.dialogVisible4 = false
					} else {
						this.$message({
							message: res.message,
							type: 'error'
						});
					}
				})
			},
			pairChange(value) {
				var str = value.toLowerCase()
				var arr = str.split('/')
				var unit = arr[0] + '_' + arr[1]
				this.$router.push({
					params: {
						id: unit
					}
				});
			},
			onOpen(direction) {
				if (this.form3.triggerPrice == "" || this.form3.triggerPrice == undefined) {
					this.form3.triggerPrice = 0;
				}
				var oType = parseInt(this.activeName2);
				if (oType == 2 && this.form3.triggerPrice <= 0) {
					this.$$message.error(this.$t("triggerpriceshouldbigger"));
					return;
				}
				if (oType == 1) {
					let temVolume = 0;
					if (this.form.sliderOpenPercent > 0) {
						if (direction == 0) {
							temVolume = parseInt(this.avaOpenBuy * this.form.sliderOpenPercent / 100);
						} else {
							temVolume = parseInt(this.avaOpenSell * this.form.sliderOpenPercent / 100);
						}

						if (temVolume < 1) {
							temVolume = 1;
						}
					} else {
						temVolume = parseInt(this.form.volume);
					}
					let entrustPrice = this.form.entrustPrice == "" ? 0 : Number(this.form.entrustPrice);

					let params = {
						type: oType, // 0:市价 1:限价 2计划委托
						direction: direction, // 买入
						contractCoinId: this.info.id,
						triggerPrice: Number(this.form3.triggerPrice),
						entrustPrice: entrustPrice,
						leverage: direction == 0 ? Number(this.buyLeverage) : Number(this.sellLeverage),
						volume: temVolume
					};
					if (params.volume <= 0) {
						this.$message.error(this.$t("pleaseinputopenvolume"));
						return;
					}
					let reg = /^\+?[1-9][0-9]*$/;
					if (!reg.test(params.volume)) {
						this.$message.error(this.$t("pleaseinputcorrectopenvolume"));
						return;
					}
					if (direction == 0) { // 买入开多(计划委托时，不需考虑额度问题)
						if (params.volume > this.avaOpenBuy && params.type != 3) {
							this.$message.error(this.$t("noenoughbalance"));
							return;
						}
					} else { // 卖出开空
						if (params.volume > this.avaOpenSell && params.type != 3) {
							this.$message.error(this.$t("noenoughbalance"));
							return;
						}
					}
					if (this.activeName2 == 1) { // 限价，必须输入价格
						if (this.form.entrustPrice == "" || this.form.entrustPrice == 0) {
							this.$message.error(this.$t("pleaseinputrightentrustprice"));
							return;
						}
					}
					orderOpen(params).then(res => {
						console.log(res);
						if (res.code == 0) {
              this.getMemberContractWallet();
							this.$notify.success({
								title: this.$t('apiTxt2'),
								message: this.$t('trSuccess'),
							})
						} else {
							this.$notify.error({
								title: this.$t("apiTxt2"),
								message: res.message
							});
						}
					})
				}
				if (oType == 0) {
					let temVolume = 0;
					if (this.form2.sliderOpenPercent > 0) {
						if (direction == 0) {
							temVolume = parseInt(this.avaOpenBuy * this.form2.sliderOpenPercent / 100);
						} else {
							temVolume = parseInt(this.avaOpenSell * this.form2.sliderOpenPercent / 100);
						}

						if (temVolume < 1) {
							temVolume = 1;
						}
					} else {
						temVolume = parseInt(this.form2.volume);
					}
					let entrustPrice = this.form2.entrustPrice == "" ? 0 : Number(this.form2.entrustPrice);
					let params = {
						type: oType, // 0:市价 1:限价 2计划委托
						direction: direction, // 买入
						contractCoinId: this.info.id,
						triggerPrice: Number(this.form3.triggerPrice),
						entrustPrice: entrustPrice,
						leverage: direction == 0 ? Number(this.buyLeverage) : Number(this.sellLeverage),
						volume: temVolume
					};
					if (params.volume <= 0) {
						this.$message.error(this.$t("pleaseinputopenvolume"));
						return;
					}
					let reg = /^\+?[1-9][0-9]*$/;
					if (!reg.test(params.volume)) {
						this.$message.error(this.$t("pleaseinputcorrectopenvolume"));
						return;
					}
					if (direction == 0) { // 买入开多(计划委托时，不需考虑额度问题)
						if (params.volume > this.avaOpenBuy && params.type != 3) {
							this.$message.error(this.$t("noenoughbalance"));
							return;
						}
					} else { // 卖出开空
						if (params.volume > this.avaOpenSell && params.type != 3) {
							this.$message.error(this.$t("noenoughbalance"));
							return;
						}
					}
					orderOpen(params).then(res => {
						console.log(res);
						if (res.code == 0) {
              this.getMemberContractWallet();
							this.$notify.success({
								title: this.$t('apiTxt2'),
								message: this.$t('trSuccess'),
							})
						} else {
							this.$notify.error({
								title: this.$t("apiTxt2"),
								message: res.message
							});
						}
					})
				}
				if (oType == 2) {
					let temVolume = 0;
					if (this.form3.sliderOpenPercent > 0) {
						if (direction == 0) {
							temVolume = parseInt(this.avaOpenBuy * this.form3.sliderOpenPercent / 100);
						} else {
							temVolume = parseInt(this.avaOpenSell * this.form3.sliderOpenPercent / 100);
						}

						if (temVolume < 1) {
							temVolume = 1;
						}
					} else {
						temVolume = parseInt(this.form3.volume);
					}
					let entrustPrice = this.form3.entrustPrice == "" ? 0 : Number(this.form3.entrustPrice);
					let params = {
						type: oType, // 0:市价 1:限价 2计划委托
						direction: direction, // 买入
						contractCoinId: this.info.id,
						triggerPrice: Number(this.form3.triggerPrice),
						entrustPrice: entrustPrice,
						leverage: direction == 0 ? Number(this.buyLeverage) : Number(this.sellLeverage),
						volume: temVolume
					};
					if (params.volume <= 0) {
						this.$message.error(this.$t("pleaseinputopenvolume"));
						return;
					}
					let reg = /^\+?[1-9][0-9]*$/;
					if (!reg.test(params.volume)) {
						this.$message.error(this.$t("pleaseinputcorrectopenvolume"));
						return;
					}
					if (direction == 0) { // 买入开多(计划委托时，不需考虑额度问题)
						if (params.volume > this.avaOpenBuy && params.type != 3) {
							this.$message.error(this.$t("noenoughbalance"));
							return;
						}
					} else { // 卖出开空
						if (params.volume > this.avaOpenSell && params.type != 3) {
							this.$message.error(this.$t("noenoughbalance"));
							return;
						}
					}
					orderOpen(params).then(res => {
						console.log(res);
						if (res.code == 0) {
              this.getMemberContractWallet();
							this.$notify.success({
								title: this.$t('apiTxt2'),
								message: this.$t('trSuccess'),
							})
						} else {
							this.$notify.error({
								title: this.$t("apiTxt2"),
								message: res.message
							});
						}
					})
				}

			},
			onClose(direction) {
				if (this.form3.triggerPrice == "" || this.form3.triggerPrice == undefined) {
					this.form3.triggerPrice = 0;
				}
				var oType = parseInt(this.activeName2);
				if (oType == 2 && this.form3.triggerPrice <= 0) {
					this.$$message.error(this.$t("triggerpriceshouldbigger"));
					return;
				}
				if (oType == 1) {
					let temVolume = 0;
					if (this.form.sliderClosePercent > 0) {
						if (direction == 0) {
							temVolume = parseInt(this.avaOpenBuy * this.form.sliderClosePercent / 100);
						} else {
							temVolume = parseInt(this.avaOpenSell * this.form.sliderClosePercent / 100);
						}

						if (temVolume < 1) {
							temVolume = 1;
						}
					} else {
						temVolume = parseInt(this.form.volume);
					}
					let entrustPrice = this.form.entrustPrice == "" ? 0 : Number(this.form.entrustPrice);

					let params = {
						type: oType, // 0:市价 1:限价 2计划委托
						direction: direction, // 买入
						contractCoinId: this.info.id,
						triggerPrice: Number(this.form3.triggerPrice),
						entrustPrice: entrustPrice,
						leverage: direction == 0 ? Number(this.buyLeverage) : Number(this.sellLeverage),
						volume: temVolume
					};
					if (params.volume <= 0) {
						this.$message.error(this.$t("pleaseinputclosevolume"));
						return;
					}
					let reg = /^\+?[1-9][0-9]*$/;
					if (!reg.test(params.volume)) {
						this.$message.error(this.$t("pleaseinputcorrectclosevolume"));
						return;
					}
					if (direction == 0) { // 买入开多(计划委托时，不需考虑额度问题)
						if (params.volume > this.contractWalletInfo.usdtSellPosition && params.type != 3) {
							this.$message.error(this.$t("noenoughposition"));
							return;
						}
					} else { // 卖出开空
						if (params.volume > this.contractWalletInfo.usdtBuyPosition && params.type != 3) {
							this.$message.error(this.$t("noenoughposition"));
							return;
						}
					}
					if (this.activeName2 == 1) { // 限价，必须输入价格
						if (this.form.entrustPrice == "" || this.form.entrustPrice == 0) {
							this.$message.error(this.$t("pleaseinputrightentrustprice"));
							return;
						}
					}
					orderClose(params).then(res => {
						console.log(res);
						if (res.code == 0) {
              this.getMemberContractWallet();
							this.$notify.success({
								title: this.$t('apiTxt2'),
								message: this.$t('trSuccess'),
							})
							this.drawer = false
						} else {
							this.$notify.error({
								title: this.$t("apiTxt2"),
								message: res.message
							});
						}
					})
				}
				if (oType == 0) {
					let temVolume = 0;
					if (this.form2.sliderOpenPercent > 0) {
						if (direction == 0) {
							temVolume = parseInt(this.avaOpenBuy * this.form2.sliderOpenPercent / 100);
						} else {
							temVolume = parseInt(this.avaOpenSell * this.form2.sliderOpenPercent / 100);
						}

						if (temVolume < 1) {
							temVolume = 1;
						}
					} else {
						temVolume = parseInt(this.form2.volume);
					}
					let entrustPrice = this.form2.entrustPrice == "" ? 0 : Number(this.form2.entrustPrice);
					let params = {
						type: oType, // 0:市价 1:限价 2计划委托
						direction: direction, // 买入
						contractCoinId: this.info.id,
						triggerPrice: Number(this.form3.triggerPrice),
						entrustPrice: entrustPrice,
						leverage: direction == 0 ? Number(this.buyLeverage) : Number(this.sellLeverage),
						volume: temVolume
					};
					if (params.volume <= 0) {
						this.$message.error(this.$t("pleaseinputclosevolume"));
						return;
					}
					let reg = /^\+?[1-9][0-9]*$/;
					if (!reg.test(params.volume)) {
						this.$message.error(this.$t("pleaseinputcorrectclosevolume"));
						return;
					}
					if (direction == 0) { // 买入开多(计划委托时，不需考虑额度问题)
						if (params.volume > this.contractWalletInfo.usdtSellPosition && params.type != 3) {
							this.$message.error(this.$t("noenoughposition"));
							return;
						}
					} else { // 卖出开空
						if (params.volume > this.contractWalletInfo.usdtBuyPosition && params.type != 3) {
							this.$message.error(this.$t("noenoughposition"));
							return;
						}
					}
					if (this.activeName2 == 1) { // 限价，必须输入价格
						if (this.form.entrustPrice == "" || this.form.entrustPrice == 0) {
							this.$message.error(this.$t("pleaseinputrightentrustprice"));
							return;
						}
					}
					orderClose(params).then(res => {
						console.log(res);
						if (res.code == 0) {
              this.getMemberContractWallet();
							this.$notify.success({
								title: this.$t('apiTxt2'),
								message: this.$t('trSuccess'),
							})
							this.drawer = false
						} else {
							this.$notify.error({
								title: this.$t("apiTxt2"),
								message: res.message
							});
						}
					})
				}
				if (oType == 2) {
					let temVolume = 0;
					if (this.form3.sliderOpenPercent > 0) {
						if (direction == 0) {
							temVolume = parseInt(this.avaOpenBuy * this.form3.sliderOpenPercent / 100);
						} else {
							temVolume = parseInt(this.avaOpenSell * this.form3.sliderOpenPercent / 100);
						}

						if (temVolume < 1) {
							temVolume = 1;
						}
					} else {
						temVolume = parseInt(this.form3.volume);
					}
					let entrustPrice = this.form3.entrustPrice == "" ? 0 : Number(this.form3.entrustPrice);
					let params = {
						type: oType, // 0:市价 1:限价 2计划委托
						direction: direction, // 买入
						contractCoinId: this.info.id,
						triggerPrice: Number(this.form3.triggerPrice),
						entrustPrice: entrustPrice,
						leverage: direction == 0 ? Number(this.buyLeverage) : Number(this.sellLeverage),
						volume: temVolume
					};
					if (params.volume <= 0) {
						this.$Message.error(this.$t("pleaseinputclosevolume"));
						return;
					}
					let reg = /^\+?[1-9][0-9]*$/;
					if (!reg.test(params.volume)) {
						this.$message.error(this.$t("pleaseinputcorrectclosevolume"));
						return;
					}
					if (direction == 0) { // 买入开多(计划委托时，不需考虑额度问题)
						if (params.volume > this.contractWalletInfo.usdtSellPosition && params.type != 3) {
							this.$message.error(this.$t("noenoughposition"));
							return;
						}
					} else { // 卖出开空
						if (params.volume > this.contractWalletInfo.usdtBuyPosition && params.type != 3) {
							this.$message.error(this.$t("noenoughposition"));
							return;
						}
					}
					if (this.activeName2 == 1) { // 限价，必须输入价格
						if (this.form.entrustPrice == "" || this.form.entrustPrice == 0) {
							this.$message.error(this.$t("pleaseinputrightentrustprice"));
							return;
						}
					}
					orderClose(params).then(res => {
						console.log(res);
						if (res.code == 0) {
              this.getMemberContractWallet();
							this.$notify.success({
								title: this.$t('apiTxt2'),
								message: this.$t('trSuccess'),
							})
							this.drawer = false
						} else {
							this.$notify.error({
								title: this.$t("apiTxt2"),
								message: res.message
							});
						}
					})
				}
			},
			radioInput(value) {
				if (!this.isLogin) {
					return;
				}
				let params = {
					contractCoinId: this.info.id,
					targetPattern: Number(value)
				};
				switchPattern(params).then(res => {
					if (res.code == 0) {
						this.$message.success(this.$t("marginModeSuccessTip"));
						this.getMemberContractWallet();
					} else {
            this.radio = value== 0 ? 1:0
						this.$message.error(res.message);
					}
				})
			},
			showAdjustLeverage(type) {
				if (!this.isLogin) return;

				this.dialogVisible2 = true;
				this.leverageType = this.info.leverageType;
				this.changeLeverageType = type;
			},
			zjTransfer() {
				this.$router.push({
					path: '/coinSwapAssets'
				})
			},
			adjustLeverage() {
				if (!this.isLogin) return;
				var params = {}
				if (this.changeLeverageType == 1) {
					params = {
						contractCoinId: this.info.id,
						leverage: this.buyLeverage,
						direction: 0
					};

				} else {
					params = {
						contractCoinId: this.info.id,
						leverage: this.sellLeverage,
						direction: 1
					}
				}
				modifyLeverage(params).then(res => {
					this.dialogVisible2 = false
					if (res.code == 0) {
						this.$notify.success({
							title: this.$t("apiTxt2"),
							message: this.$t("trSuccess")
						});
						this.getMemberContractWallet();
					} else {
						this.$notify.error({
							title: this.$t("apiTxt2"),
							message: res.message
						});
					}
				})

			},
			currentClick(index) {
				this.current = index
				if (index == 2) {
					this.getPlateFull()
				}
			},
			// keyup(e) {
			// 	e.target.value = e.target.value.replace(/[^\d]/g, "")
			// },
			entrustPriceChange(value){
				if (value != '') {
					if (value.indexOf('.') > -1) {
						this.form.entrustPrice = value.slice(0, value.indexOf('.') + 3)
					} else {
						this.form.entrustPrice = value
					}
				}
			},
			triggerPriceChange(value){
				if (value != '') {
					if (value.indexOf('.') > -1) {
						this.form3.triggerPrice = value.slice(0, value.indexOf('.') + 3)
					} else {
						this.form3.triggerPrice = value
					}
				}
			},
			entrustPriceChange3(value){
				if (value != '') {
					if (value.indexOf('.') > -1) {
						this.form3.entrustPrice = value.slice(0, value.indexOf('.') + 3)
					} else {
						this.form3.entrustPrice = value
					}
				}
			},
			showMarginModeModal() {
				if (!this.isLogin) return;
				this.dialogVisible3 = true;
			}
		}
	}
</script>
